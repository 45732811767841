import React from 'react';
import { Field, FieldProps } from 'formik';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Container,
  Box,
  FormHelperText,
} from '@mui/material';
import { FormPageComponentProps } from 'types/form.types';

const I131ApplicationType: React.FC<FormPageComponentProps> = ({ formikProps }): JSX.Element => {
  const { touched, errors, setFieldValue, setFieldTouched } = formikProps;

  const applicationTypeOptions = [
    {
      id: '1',
      label:
        'I am a lawful permanent resident or conditional permanent resident of the United States, and I am applying for a reentry permit.',
    },
    {
      id: '2',
      label:
        'I now hold refugee or asylee status in the United States, and I am applying for a Refugee Travel Document.',
    },
    {
      id: '3',
      label:
        'I am a lawful permanent resident as a direct result of refugee or asylee status, and I am applying for a Refugee Travel Document.',
    },
    {
      id: '4',
      label:
        'I am a TPS beneficiary in the United States, and I am applying for a TPS Travel Authorization Document under the Immigration and Nationality Act (INA) section 244(f)(3) to allow me to seek admission under TPS upon my return from abroad.',
    },
    {
      id: '5',
      label:
        'I am located inside the United States, and I am applying for an Advance Parole Document to allow me to seek parole into the United States under INA section 212(d)(5)(A) upon my return from abroad.',
    },
    {
      id: '6',
      label:
        'I am applying for a parole document under INA section 212(d)(5)(A) on my own behalf and I am outside the United States, or I am applying on behalf of someone else who is outside the United States, for the first time (initial application) under one of the following specific parole programs or processes.',
    },
    {
      id: '7',
      label:
        'I am applying for a parole document under INA section 212(d)(5)(A) for myself and I am outside the United States, or I am applying for a parole document under INA section 212(d)(5)(A) on behalf of someone else who is outside the United States for the first time (initial application), but not under a specific parole program or process.',
    },
    {
      id: '8',
      label:
        'I am applying for an initial period of parole in place under INA section 212(d)(5)(A) and I am inside the United States, or I am applying for an initial period of parole in place under INA section 212(d)(5)(A) on behalf of someone else who is inside the United States.',
    },
    {
      id: '9',
      label:
        'I am applying for an initial period of parole in place under INA section 212(d)(5)(A) and I am inside the United States, but not under a specific program or process, or I am applying for an initial period of parole in place under INA section 212(d)(5)(A) for someone else who is inside the United States, but not under a specific program or process.',
    },
    {
      id: '10',
      label:
        'I was initially paroled into the United States or granted parole in place under INA section 212(d)(5)(A) under one of the following programs or processes and I am requesting a new period of parole, or I am applying for a new period of parole on behalf of someone else who was initially paroled into the United States under one of the following programs or processes:',
    },
    {
      id: '11',
      label:
        'I was initially paroled into the United States or granted parole in place under INA section 212(d)(5)(A) and I am requesting a new period of parole, but not under a specific program or process, or I am requesting a new period of parole on behalf of someone else who was initially paroled into the United States or granted parole in place, but not under a specific program or process.',
    },
  ];

  const yesNoOptions = [
    { id: 'Yes', label: 'Yes' },
    { id: 'No', label: 'No' },
  ];

  const clearDependentFields = (applicationType: string): void => {
    if (applicationType !== '4') {
      setFieldValue('tpsBeneficiary.tpsReceiptNumber', '');
    }
    if (applicationType !== '5') {
      setFieldValue('inUsAdvanceParoleDocument.program', '');
      setFieldValue('inUsAdvanceParoleDocument.receiptNumber', '');
    }
    if (applicationType !== '6') {
      setFieldValue('outsideUsInitParoleDocument.program', '');
      setFieldValue('outsideUsInitParoleDocument.forPerson', '');
      setFieldValue('outsideUsInitParoleDocument.receiptNumber', '');
      setFieldValue('outsideUsInitParoleDocument.govExecBranch', '');
      setFieldValue('outsideUsInitParoleDocument.repEmailAddress', '');
      setFieldValue('outsideUsInitParoleDocument.otherProgram', '');
    }
    if (applicationType !== '8') {
      setFieldValue('selfOrOtherInUsInitParole.program', '');
      setFieldValue('selfOrOtherInUsInitParole.forPersonStatus', '');
      setFieldValue('selfOrOtherInUsInitParole.receiptNumber', '');
      setFieldValue('selfOrOtherInUsInitParole.otherProgram', '');
    }
    if (applicationType !== '10') {
      setFieldValue('selfOrOtherRenewParole.program', '');
      setFieldValue('selfOrOtherRenewParole.forPerson', '');
      setFieldValue('selfOrOtherRenewParole.forPersonStatus', '');
      setFieldValue('selfOrOtherRenewParole.otherProgram', '');
    }
    if (applicationType !== '10' && applicationType !== '11') {
      setFieldValue('i94AdmittedDate', '');
    }
  };

  const handleApplicationTypeChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newValue = e.target.value;
    await setFieldTouched('applicationType', true, false);
    clearDependentFields(newValue);
    await setFieldValue('applicationType', newValue, true);
  };

  const handleRefugeeStatusChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    await setFieldTouched('refugeeOrLprRefugee', true, false);
    await setFieldValue('refugeeOrLprRefugee', e.target.value, true);
  };

  const renderApplicationTypeField = ({ field }: FieldProps): JSX.Element => (
    <RadioGroup
      {...field}
      onChange={handleApplicationTypeChange}
      aria-label="application type"
    >
      {applicationTypeOptions.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={option.label}
          sx={{
            marginBottom: 1,
            '.MuiFormControlLabel-label': {
              fontSize: '0.875rem',
              lineHeight: 1.5,
            },
          }}
        />
      ))}
    </RadioGroup>
  );

  const renderRefugeeStatusField = ({ field }: FieldProps): JSX.Element => (
    <RadioGroup
      {...field}
      onChange={handleRefugeeStatusChange}
      aria-label="refugee status"
    >
      {yesNoOptions.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={option.label}
          sx={{
            marginBottom: 1,
            '.MuiFormControlLabel-label': {
              fontSize: '0.875rem',
              lineHeight: 1.5,
            },
          }}
        />
      ))}
    </RadioGroup>
  );

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 4 }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
        >
          What type of application are you applying for?
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          You must complete all fields with an asterisk (*) to submit this form.
        </Typography>
      </Box>

      <FormControl
        component="fieldset"
        error={touched.applicationType && Boolean(errors.applicationType)}
        sx={{ width: '100%', maxWidth: '800px' }}
      >
        <FormLabel
          component="legend"
          sx={{ typography: 'h6', mb: 2 }}
        >
          Part 1. Application Type *
        </FormLabel>
        <FormLabel
          component="legend"
          sx={{ typography: 'body2', mb: 2, color: 'text.secondary' }}
        >
          Select the application type below.
        </FormLabel>

        <Field name="applicationType">{renderApplicationTypeField}</Field>
        {touched.applicationType && errors.applicationType && (
          <FormHelperText error>{errors.applicationType}</FormHelperText>
        )}

        <FormControl
          component="fieldset"
          error={touched.refugeeOrLprRefugee && Boolean(errors.refugeeOrLprRefugee)}
          sx={{ mt: 4 }}
        >
          <FormLabel
            component="legend"
            sx={{ typography: 'body2', mb: 1, color: 'text.primary' }}
          >
            Do you hold status as a refugee, were you paroled as a refugee, or are you a lawful permanent resident as a
            direct result of being a refugee? *
          </FormLabel>

          <Field name="refugeeOrLprRefugee">{renderRefugeeStatusField}</Field>
          {touched.refugeeOrLprRefugee && errors.refugeeOrLprRefugee && (
            <FormHelperText error>{errors.refugeeOrLprRefugee}</FormHelperText>
          )}
        </FormControl>
      </FormControl>
    </Container>
  );
};

export default I131ApplicationType;
