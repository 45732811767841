import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type QualifyForParoleSchemaType = Partial<FormValues> & {
  initPipReparole: {
    qualification: string;
  };
};

const QualifyForParoleSchema = createPartialSchema<QualifyForParoleSchemaType>({
  initPipReparole: Yup.object({
    qualification: Yup.string().default(''),
  }),
});

export default QualifyForParoleSchema;
