/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const LengthOfStayInUSPage: React.FC = () => {
  return (
    <Field name="initPipReparole.lengthOfStayInUs">
      {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
        >
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 2,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            Expected Length of Stay in the United States
          </FormLabel>

          <TextField
            {...field}
            type="text"
            inputProps={{
              pattern: '[0-9]*',
              'aria-label': 'Expected length of stay in days',
            }}
            value={field.value || ''}
            onChange={(e): void => {
              const value = e.target.value;
              // Only allow numbers and empty string
              if (value === '' || /^\d+$/.test(value)) {
                // Convert to string but ensure it's within range
                if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 999)) {
                  form.setFieldValue(field.name, value);
                }
              }
            }}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />

          {meta.touched && meta.error && (
            <Typography
              color="error"
              variant="caption"
              sx={{ mt: 1 }}
            >
              {meta.error}
            </Typography>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default LengthOfStayInUSPage;
