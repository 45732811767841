import * as React from 'react';
import BooleanRadioPage from '../../SharedPages/BooleanRadio/BooleanRadio';

const PreviousProceedingsPage: React.FC = () => {
  return (
    <BooleanRadioPage
      question="Has the person who will receive the travel document, parole document, or Arrival/Departure Record, if approved, been in any exclusion, deportation, removal, or rescission proceedings?"
      fieldName="processingInfo.previousProceedings"
      required
    />
  );
};

export default PreviousProceedingsPage;
