import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
  QueryFunction,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import { FORM_QUERY_KEY } from 'containers/FormFactory/FormFactory';
import { FormValues } from 'types/form.types';
import { StoredFormData } from 'types/services.types';

// const FORM_QUERY_KEY = 'forms';

export interface SaveFormDataPayload {
  id: string;
  values: FormValues;
  formType: string;
  lastUpdated: string;
}

export interface FormDataError {
  message: string;
  code?: string;
  details?: unknown;
}

export interface FormDataOptions
  extends Partial<
    Omit<UseQueryOptions<StoredFormData | null, Error, StoredFormData | null, [string, string]>, 'queryKey' | 'queryFn'>
  > {
  cacheTime?: number;
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
}

export interface SaveFormDataMutationOptions {
  onError?: (error: FormDataError) => void;
  onSuccess?: (data: StoredFormData) => void;
}

class FormStorageAdapter {
  private static getStorageKey(formId: string): string {
    return `form_${formId}`;
  }

  static getAllForms(): Promise<StoredFormData[]> {
    return new Promise((resolve) => {
      const forms: StoredFormData[] = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith('form_')) {
          const data = localStorage.getItem(key);
          if (data) {
            forms.push(JSON.parse(data));
          }
        }
      }
      resolve(forms);
    });
  }

  static getForm(formId: string): Promise<StoredFormData | null> {
    return new Promise((resolve) => {
      const data = localStorage.getItem(this.getStorageKey(formId));
      resolve(data ? JSON.parse(data) : null);
    });
  }

  static saveForm(formData: StoredFormData): Promise<StoredFormData> {
    return new Promise((resolve) => {
      localStorage.setItem(this.getStorageKey(formData.id), JSON.stringify(formData));
      resolve(formData);
    });
  }

  static deleteForm(formId: string): Promise<string> {
    return new Promise((resolve) => {
      localStorage.removeItem(this.getStorageKey(formId));
      resolve(formId);
    });
  }

  static async getFormData(formId: string): Promise<StoredFormData | null> {
    return this.getForm(formId);
  }

  static async saveFormData(data: SaveFormDataPayload): Promise<StoredFormData> {
    const formData: StoredFormData = {
      id: data.id,
      values: data.values,
      formType: data.formType,
      lastUpdated: data.lastUpdated,
    };
    return this.saveForm(formData);
  }
}

export function useAllFormsData(): UseQueryResult<StoredFormData[]> {
  return useQuery({
    queryKey: [FORM_QUERY_KEY],
    queryFn: () => FormStorageAdapter.getAllForms(),
  });
}

export function useDeleteFormData(): UseMutationResult<string, Error, string, unknown> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formId: string) => FormStorageAdapter.deleteForm(formId),
    onSuccess: (formId) => {
      queryClient.removeQueries({
        queryKey: [FORM_QUERY_KEY, formId],
      });

      queryClient.setQueryData<StoredFormData[]>(
        [FORM_QUERY_KEY],
        (old) => old?.filter((form) => form.id !== formId) ?? []
      );
    },
  });
}

type FormDataQueryKey = readonly [typeof FORM_QUERY_KEY, string];

const getFormData: QueryFunction<StoredFormData | null, FormDataQueryKey> = async ({ queryKey }) => {
  const [, id] = queryKey;
  return FormStorageAdapter.getFormData(id);
};

export const useFormData = (
  formId: string,
  options?: Omit<
    UseQueryOptions<StoredFormData | null, Error, StoredFormData | null, FormDataQueryKey>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<StoredFormData | null, Error> => {
  return useQuery<StoredFormData | null, Error, StoredFormData | null, FormDataQueryKey>({
    queryKey: [FORM_QUERY_KEY, formId] as const,
    queryFn: getFormData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!formId,
    ...options,
  });
};

export function useSaveFormData(
  options?: SaveFormDataMutationOptions
): UseMutationResult<StoredFormData, FormDataError, SaveFormDataPayload, unknown> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveFormDataPayload) => FormStorageAdapter.saveFormData(data),
    onSuccess: (savedData) => {
      queryClient.setQueryData([FORM_QUERY_KEY, savedData.id], savedData);
      options?.onSuccess?.(savedData);
    },
    onError: options?.onError,
  });
}
