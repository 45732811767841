import * as React from 'react';
import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, FormLabel, FormHelperText, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { COUNTRY } from '../../../constants/countryConstants';

interface CountryOption {
  label: string;
  value: string;
}

export const ORIGIN_COUNTRY = {
  ORIGIN_COUNTRY: {
    fieldKey: 'refugeeTravelDocumentInfo.originCountry',
    question: 'What country are you a refugee or asylee from?',
  },
} as const;

const CountryOriginSelect = (): ReactElement => {
  // Updated handler to accept both SelectChangeEvent and React.ChangeEvent
  const handleSelectChange = (
    field: FieldProps['field'],
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  return (
    <Field name={ORIGIN_COUNTRY.ORIGIN_COUNTRY.fieldKey}>
      {({ field, meta }: FieldProps): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
          sx={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            sx={{
              fontSize: '1rem',
              mb: 2,
              color: 'text.primary',
            }}
          >
            {ORIGIN_COUNTRY.ORIGIN_COUNTRY.question}
          </FormLabel>
          <Select
            {...field}
            value={field.value || ''}
            onChange={(event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>): void =>
              handleSelectChange(field, event)
            }
            sx={{
              '& .MuiSelect-select': {
                p: 2,
              },
              borderRadius: 1,
              bgcolor: 'background.paper',
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              Select a country
            </MenuItem>
            {COUNTRY.map((country: CountryOption) => (
              <MenuItem
                key={country.value}
                value={country.value}
              >
                {country.label}
              </MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default CountryOriginSelect;
