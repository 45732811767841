/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, MenuItem, Select, FormHelperText } from '@mui/material';

const hairColorOptions = [
  { value: 'Bald', label: 'Bald' },
  { value: 'Black', label: 'Black' },
  { value: 'Blond', label: 'Blond' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Red', label: 'Red' },
  { value: 'Sandy', label: 'Sandy' },
  { value: 'White', label: 'White' },
  { value: 'Unknown', label: 'Unknown' },
];

const HairColorPage: React.FC = () => {
  return (
    <Field name="biographicInfo.hairColor">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
          sx={{ width: '100%' }}
        >
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 1,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            What is the hair color of the person who will receive the document?
          </FormLabel>

          <Select
            {...field}
            displayEmpty
            sx={{
              mt: 2,
              backgroundColor: 'background.paper',
              '& .MuiSelect-select': {
                padding: '12px 16px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BDBDBD',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              <em>Select hair color</em>
            </MenuItem>
            {hairColorOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: '1rem',
                  '&.Mui-selected': {
                    backgroundColor: '#EBF3FF',
                    '&:hover': {
                      backgroundColor: '#E3EDF9',
                    },
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default HairColorPage;
