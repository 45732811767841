import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

interface QueryDebugInfo {
  key: unknown;
  state: unknown;
}

interface QueryCacheItem {
  queryKey: unknown;
  state: unknown;
}

const EnhancedFormDebugWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const unmountingRef = useRef<boolean>(false);
  const mountTimeRef = useRef<number>(Date.now());

  useEffect((): (() => void) => {
    unmountingRef.current = false;
    mountTimeRef.current = Date.now();

    const handleUnmount = (): void => {
      unmountingRef.current = true;
      const unmountTime = Date.now();
      const mountDuration = unmountTime - mountTimeRef.current;

      if (mountDuration > 100 && location.pathname.includes('/form')) {
        console.log('Enhanced Form Debug - Unmounting:', {
          timestamp: unmountTime,
          authState: auth.isAuthenticated,
          pathname: location.pathname,
          mountDuration,
          queries: queryClient
            .getQueryCache()
            .getAll()
            .map(
              (q: QueryCacheItem): QueryDebugInfo => ({
                key: q.queryKey,
                state: q.state,
              })
            ),
        });
      }
    };

    return handleUnmount;
  }, [auth.isAuthenticated, location.pathname, queryClient]);

  useEffect((): (() => void) => {
    const handleLocationChange = (): void => {
      if (!unmountingRef.current && location.pathname.includes('/form')) {
        unmountingRef.current = true;
      }
    };

    return handleLocationChange;
  }, [location.pathname]);

  return <div className="debug-wrapper">{children}</div>;
};

export default EnhancedFormDebugWrapper;
