import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '../../SharedPages/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const RETURN_TO_COUNTRY = {
  RETURN_TO_COUNTRY: {
    fieldKey: 'refugeeTravelDocumentInfo.returnToCountry',
    question: 'Since you were accorded refugee/asylee status, have you ever returned to that country?',
  },
  RETURN_TO_COUNTRY_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.returnToCountryExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const ReturnToCountryQuestion = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={RETURN_TO_COUNTRY.RETURN_TO_COUNTRY.question}
      fieldName={RETURN_TO_COUNTRY.RETURN_TO_COUNTRY.fieldKey}
    />
  );
};

export default ReturnToCountryQuestion;
