/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Typography,
} from '@mui/material';
import { RaceType } from 'src/utils/I131Json.schema';

const raceOptions = [
  { value: 'americanIndianOrAlaskaNative', label: 'American Indian or Alaska Native' },
  { value: 'asian', label: 'Asian' },
  { value: 'blackOrAfricanAmerican', label: 'Black or African American' },
  { value: 'nativeHawaiianOrPacificIslander', label: 'Native Hawaiian or Other Pacific Islander' },
  { value: 'white', label: 'White' },
];

const RacePage: React.FC = () => {
  return (
    <Field name="biographicInfo.race">
      {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
        <FormControl
          component="fieldset"
          error={meta.touched && !!meta.error}
          sx={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 1,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            What is the race of the person who will receive the document?
          </FormLabel>

          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: 'text.secondary',
              fontSize: '0.875rem',
            }}
          >
            Select all that apply
          </Typography>

          <FormGroup>
            {raceOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Checkbox
                    checked={field.value?.includes(option.value)}
                    onChange={(): void => {
                      const prev = field.value;
                      const curr = prev.includes(option.value)
                        ? prev.filter((item: RaceType) => item !== option.value)
                        : [...prev, option.value];
                      form.setFieldValue(
                        field.name,
                        curr.filter((v: string) => !!v)
                      );
                    }}
                    sx={{
                      '&.MuiCheckbox-root': {
                        color: '#757575',
                      },
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                      padding: '6px 9px',
                    }}
                  />
                }
                label={option.label}
                sx={{
                  marginLeft: 0,
                  marginY: 0.5,
                  '& .MuiFormControlLabel-label': {
                    fontSize: '1rem',
                    color: 'text.primary',
                  },
                }}
              />
            ))}
          </FormGroup>

          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default RacePage;
