import { ReactElement } from 'react';
import { CommonLabel } from 'components';
import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { DropdownInputProps } from 'types/base.types';

function DropdownInput({
  name,
  label,
  error,
  errorText,
  handleChange,
  handleBlur,
  options,
  placeholder = 'Select one',
  value,
}: DropdownInputProps): ReactElement {
  return (
    <Grid>
      <Grid>
        <CommonLabel
          label={label}
          name={`label-dropdown-${name}`}
        />
      </Grid>
      <FormControl
        fullWidth
        error={error}
        variant="standard"
      >
        <Select
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          displayEmpty
        >
          <MenuItem value="">{placeholder}</MenuItem>
          {options.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

export default DropdownInput;
