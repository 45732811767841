import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type LengthOfStayInUSSchemaType = Partial<FormValues> & {
  initPipReparole: {
    lengthOfStayInUs: string;
  };
};

const LengthOfStayInUSSchema = createPartialSchema<LengthOfStayInUSSchemaType>({
  initPipReparole: Yup.object({
    lengthOfStayInUs: Yup.string().default(''),
  }),
});

export default LengthOfStayInUSSchema;
