import { createPartialSchema, FormValues } from '../../../types/form.types';
import { booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

export const validInfoCorrectionValue = [
  'name',
  'aNumber',
  'countryOfBirth',
  'termsAndConditions',
  'dateOfBirth',
  'gender',
  'validityDate',
  'photo',
];

export function validateInfoCorrection(value: (string | undefined)[]): boolean {
  if (value.length === 0) return true;

  return value.every((v) => v && validInfoCorrectionValue.includes(v));
}

type requestReplacementSchemaType = Partial<FormValues> & {
  processingInfo: {
    requestReplacement: string;
    reasonForReplacement: string;
    infoCorrection: string[];
    infoCorrectionExplanation: string;
    replacementReceiptNumber: string;
  };
};

const requestReplacementSchema = createPartialSchema<requestReplacementSchemaType>({
  processingInfo: Yup.object({
    requestReplacement: booleanSchema,
    reasonForReplacement: Yup.string()
      .default('')
      .oneOf(['', 'notReceive', 'stolenOrDamage', 'informationErrorCausedByMe', 'informationErrorCausedByUSCIS']),
    infoCorrection: Yup.array()
      .of(Yup.string())
      .default([])
      .test('validSelection', 'Invalid value', validateInfoCorrection),
    infoCorrectionExplanation: Yup.string().default(''),
    replacementReceiptNumber: Yup.string().default(''),
  }),
});

export type RequestReplacementSchema = Yup.InferType<typeof requestReplacementSchema>;

export default requestReplacementSchema;
