import React from 'react';
import { Field } from 'formik';
import { TextField, Box, Typography } from '@mui/material';
import { FieldRenderProps, FormPageComponentProps } from 'types/form.types';

const I94Info: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { touched, errors } = formikProps;

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        I-94 Information
      </Typography>
      <Field name="i94AdmittedDate">
        {({ field }: FieldRenderProps): JSX.Element => (
          <TextField
            {...field}
            label="Until Date/Parole shown on Form I-94"
            variant="outlined"
            fullWidth
            margin="normal"
            error={touched.i94AdmittedDate && !!errors.i94AdmittedDate}
            helperText={touched.i94AdmittedDate && !!errors.i94AdmittedDate}
            placeholder="mm/dd/yyyy"
          />
        )}
      </Field>
    </Box>
  );
};

export default I94Info;
