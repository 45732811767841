import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type ReceiveBenefitsSchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    receiveBenefits: BooleanSchema;
    receiveBenefitsExplanation: string;
  };
};

const receiveBenefitsSchema = createPartialSchema<ReceiveBenefitsSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    receiveBenefits: booleanSchema,
    receiveBenefitsExplanation: Yup.string().default(''),
  }),
});

export default receiveBenefitsSchema;
