import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { FieldRenderProps, FormPageComponentProps } from 'types/form.types';

export const OutsideInitialParoleInfo: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { values, touched, errors, setFieldValue } = formikProps;

  const outsideUsParoleOptions = [
    { id: 'A', label: 'Filipino World War II Veterans Parole (FWVP) Program, Form I-130 receipt number:' },
    { id: 'B', label: 'Immigrant Military Members and Veterans Initiative (IMMVI)' },
    { id: 'C', label: 'Intergovernmental Parole Referral' },
    { id: 'D', label: 'Family Reunification Task Force (FRTF) Process;' },
    { id: 'E', label: 'Other: (List specific parole program or process)' },
  ];

  const serviceMemberOptions = [
    { id: '1', label: 'A current or former service member' },
    {
      id: '2',
      label:
        'A current spouse, child, or unmarried son or daughter (or their child under 21 years of age) of a current or former service member',
    },
    { id: '3', label: 'Current legal guardian or surrogate of a current or former service member' },
  ];

  const clearOutsideUsFields = (program: string): void => {
    if (program !== 'A' && program !== 'D') {
      setFieldValue('outsideUsInitParoleDocument.receiptNumber', '');
    }
    if (program !== 'B') {
      setFieldValue('outsideUsInitParoleDocument.forPerson', '');
    }
    if (program !== 'C') {
      setFieldValue('outsideUsInitParoleDocument.govExecBranch', '');
      setFieldValue('outsideUsInitParoleDocument.repEmailAddress', '');
    }
    if (program !== 'E') {
      setFieldValue('outsideUsInitParoleDocument.otherProgram', '');
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        Outside US Initial Parole Information
      </Typography>
      <FormControl
        component="fieldset"
        error={touched.outsideUsInitParoleDocument?.program && Boolean(errors.outsideUsInitParoleDocument?.program)}
        sx={{ width: '100%', maxWidth: '800px' }}
      >
        <FormLabel component="legend">Under one of the following specific parole programs or processes:</FormLabel>
        <Field name="outsideUsInitParoleDocument.program">
          {({ field }: FieldRenderProps): JSX.Element => (
            <RadioGroup
              {...field}
              onChange={(e): void => {
                const newValue = e.target.value;
                clearOutsideUsFields(newValue);
                setFieldValue('outsideUsInitParoleDocument.program', newValue);
              }}
            >
              {outsideUsParoleOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        {touched.outsideUsInitParoleDocument?.program && errors.outsideUsInitParoleDocument?.program && (
          <FormHelperText>{errors.outsideUsInitParoleDocument.program}</FormHelperText>
        )}

        {['A', 'D'].includes(values.outsideUsInitParoleDocument?.program) && (
          <Field name="outsideUsInitParoleDocument.receiptNumber">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label={
                  values.outsideUsInitParoleDocument?.program === 'A'
                    ? 'Form I-130 Receipt Number'
                    : 'Task Force Registration Number'
                }
                variant="outlined"
                fullWidth
                margin="normal"
                error={
                  touched.outsideUsInitParoleDocument?.receiptNumber &&
                  Boolean(errors.outsideUsInitParoleDocument?.receiptNumber)
                }
                helperText={
                  touched.outsideUsInitParoleDocument?.receiptNumber &&
                  errors.outsideUsInitParoleDocument?.receiptNumber
                }
              />
            )}
          </Field>
        )}

        {values.outsideUsInitParoleDocument?.program === 'B' && (
          <FormControl
            component="fieldset"
            error={
              touched.outsideUsInitParoleDocument?.forPerson && Boolean(errors.outsideUsInitParoleDocument?.forPerson)
            }
            sx={{ mt: 2, ml: 4 }}
          >
            <FormLabel component="legend">Select one of the following:</FormLabel>
            <Field name="outsideUsInitParoleDocument.forPerson">
              {({ field }: FieldRenderProps): JSX.Element => (
                <RadioGroup {...field}>
                  {serviceMemberOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.label}
                      sx={{
                        marginBottom: 1,
                        '.MuiFormControlLabel-label': {
                          fontSize: '0.875rem',
                          lineHeight: 1.5,
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
            {touched.outsideUsInitParoleDocument?.forPerson && errors.outsideUsInitParoleDocument?.forPerson && (
              <FormHelperText>{errors.outsideUsInitParoleDocument.forPerson}</FormHelperText>
            )}
          </FormControl>
        )}

        {values.outsideUsInitParoleDocument?.program === 'C' && (
          <>
            <Field name="outsideUsInitParoleDocument.govExecBranch">
              {({ field }: FieldRenderProps): JSX.Element => (
                <TextField
                  {...field}
                  label="U.S. Federal Executive Branch Government Agency"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={
                    touched.outsideUsInitParoleDocument?.govExecBranch &&
                    Boolean(errors.outsideUsInitParoleDocument?.govExecBranch)
                  }
                  helperText={
                    touched.outsideUsInitParoleDocument?.govExecBranch &&
                    errors.outsideUsInitParoleDocument?.govExecBranch
                  }
                />
              )}
            </Field>
            <Field name="outsideUsInitParoleDocument.repEmailAddress">
              {({ field }: FieldRenderProps): JSX.Element => (
                <TextField
                  {...field}
                  label="U.S. Federal Government Agency Representative Official Email Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={
                    touched.outsideUsInitParoleDocument?.repEmailAddress &&
                    Boolean(errors.outsideUsInitParoleDocument?.repEmailAddress)
                  }
                  helperText={
                    touched.outsideUsInitParoleDocument?.repEmailAddress &&
                    errors.outsideUsInitParoleDocument?.repEmailAddress
                  }
                />
              )}
            </Field>
          </>
        )}

        {values.outsideUsInitParoleDocument?.program === 'E' && (
          <Field name="outsideUsInitParoleDocument.otherProgram">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label="List specific parole program or process"
                variant="outlined"
                fullWidth
                margin="normal"
                error={
                  touched.outsideUsInitParoleDocument?.otherProgram &&
                  Boolean(errors.outsideUsInitParoleDocument?.otherProgram)
                }
                helperText={
                  touched.outsideUsInitParoleDocument?.otherProgram && errors.outsideUsInitParoleDocument?.otherProgram
                }
              />
            )}
          </Field>
        )}
      </FormControl>
    </Box>
  );
};

export default OutsideInitialParoleInfo;
