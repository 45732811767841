import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type ReacquiredNationalitySchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    reacquiredNationality: BooleanSchema;
    reacquiredNationalityExplanation: string;
  };
};

const reacquiredNationalitySchema = createPartialSchema<ReacquiredNationalitySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    reacquiredNationality: booleanSchema,
    reacquiredNationalityExplanation: Yup.string().default(''),
  }),
});

export default reacquiredNationalitySchema;
