import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type AppliedForPassportSchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    appliedForPassport: BooleanSchema;
    appliedForPassportExplanation: string;
  };
};

const appliedForPassportSchema = createPartialSchema<AppliedForPassportSchemaType>({
  biographicInfo: Yup.object({
    appliedForPassport: booleanSchema,
    appliedForPassportExplanation: Yup.string().default(''),
  }),
});

export default appliedForPassportSchema;
