import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, FormHelperText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface BooleanRadioPageProps {
  fieldName: string;
  question: string;
  required?: boolean;
}

const BooleanRadio = ({ fieldName, question, required = false }: BooleanRadioPageProps): ReactElement => {
  return (
    <Field name={fieldName}>
      {({ field, meta }: FieldProps): ReactElement => (
        <FormControl
          component="fieldset"
          error={meta.touched && !!meta.error}
          sx={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            sx={{
              fontSize: '1rem',
              mb: 2,
              color: 'text.primary',
            }}
            required={required}
          >
            {question}
          </FormLabel>
          <RadioGroup
            {...field}
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
          >
            <Box
              sx={{
                border: 1,
                borderColor: field.value === 'No' ? 'primary.main' : 'grey.300',
                borderRadius: 1,
                p: 2,
                flex: 1,
                bgcolor: field.value === 'No' ? 'primary.50' : 'background.paper',
              }}
            >
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    icon={<CancelIcon sx={{ color: 'primary.main' }} />}
                    checkedIcon={<CancelIcon sx={{ color: 'primary.main' }} />}
                  />
                }
                label="No"
                sx={{
                  width: '100%',
                  margin: 0,
                  '.MuiFormControlLabel-label': {
                    flex: 1,
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                border: 1,
                borderColor: field.value === 'Yes' ? 'primary.main' : 'grey.300',
                borderRadius: 1,
                p: 2,
                flex: 1,
                bgcolor: field.value === 'Yes' ? 'primary.50' : 'background.paper',
              }}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    icon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                    checkedIcon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                  />
                }
                label="Yes"
                sx={{
                  width: '100%',
                  margin: 0,
                  '.MuiFormControlLabel-label': {
                    flex: 1,
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
          </RadioGroup>
          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default BooleanRadio;
