import * as Yup from 'yup';
import { booleanSchema } from '../../../utils/I131.schema';
import { createPartialSchema, FormValues } from '../../../types/form.types';

type HasPreparerSchemaType = Partial<FormValues> & {
  preparerContactAndCertification: {
    hasPreparer: string;
  };
};

const HasPreparerSchema = createPartialSchema<HasPreparerSchemaType>({
  preparerContactAndCertification: Yup.object({
    hasPreparer: booleanSchema,
  }),
});

export default HasPreparerSchema;
