/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, TextField, Typography } from '@mui/material';
import { Field } from 'formik';
import { ReactElement } from 'react';

interface CertificationSignatureProps {
  question: string;
  instructions?: string;
  signatureLabel: string;
  languagePath?: string;
  signaturePath: string;
  datePath: string;
}

const CertificationSignaturePage = ({
  question,
  instructions,
  signatureLabel,
  languagePath,
  signaturePath,
  datePath,
}: CertificationSignatureProps): ReactElement => {
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 3 }}>
      {question && (
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            mb: 3,
          }}
        >
          {question}
        </Typography>
      )}
      {instructions && (
        <Typography
          component="span"
          gutterBottom
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            mb: 3,
          }}
        >
          {instructions}
        </Typography>
      )}
      {languagePath && (
        <Box sx={{ mb: 4 }}>
          <Typography
            component="span"
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            I certify, under penalty of perjury, that I am fluent in English and
          </Typography>
          <Field name={languagePath}>
            {({ field, meta }: any): ReactElement => (
              <TextField
                {...field}
                variant="outlined"
                size="small"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  width: '200px',
                  mx: 1,
                  verticalAlign: 'middle',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            )}
          </Field>
          <Typography
            component="span"
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            and I have interpreted every question on the application and Instructions and interpreted the
            applicant&apos;s answers to the questions in that language, and the applicant informed me that they
            understood every instruction, question, and answer on the application.
          </Typography>
        </Box>
      )}
      <Box sx={{ mb: 3 }}>
        <div className="input-field-container">
          <label
            htmlFor="signature"
            className="input-label"
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
            >
              {signatureLabel}
            </Typography>
          </label>
          <Field name={signaturePath}>
            {({ field, meta }: any): ReactElement => (
              <TextField
                {...field}
                id="signature"
                fullWidth
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            )}
          </Field>
        </div>
      </Box>

      <Box>
        <div className="input-field-container">
          <label
            htmlFor="signature-date"
            className="input-label"
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
            >
              Date of signature
            </Typography>
          </label>
          <Field name={datePath}>
            {({ field, meta }: any): ReactElement => (
              <TextField
                {...field}
                id="signature-date"
                fullWidth
                variant="outlined"
                placeholder="MM/DD/YYYY"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            )}
          </Field>
        </div>
      </Box>
    </Box>
  );
};

export default CertificationSignaturePage;
