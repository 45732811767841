import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { KeycloakTokenData, UseKeycloakAuthReturn, UserData } from 'types/hooks.types';

const QUERY_KEYS = {
  user: 'user',
} as const;

const useKeycloakAuth = (): UseKeycloakAuthReturn => {
  const auth = useAuth();

  // Parse token and extract user data
  const parseToken = (token: string): UserData => {
    const decoded = jwtDecode<KeycloakTokenData>(token);
    return {
      id: decoded.sub,
      email: decoded.email,
      username: decoded.preferred_username,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      roles: decoded.realm_access.roles,
    };
  };

  // Set up axios interceptor for token
  const setupAxiosInterceptor = (token: string): void => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  // Remove axios interceptor
  const removeAxiosInterceptor = (): void => {
    delete axios.defaults.headers.common['Authorization'];
  };

  // Query for user data
  const { data: userData } = useQuery({
    queryKey: [QUERY_KEYS.user],
    queryFn: (): UserData => {
      if (!auth.isAuthenticated || !auth.user?.access_token) {
        throw new Error('Not authenticated');
      }
      return parseToken(auth.user.access_token);
    },
    enabled: auth.isAuthenticated && !!auth.user?.access_token,
    staleTime: Infinity, // Token data won't change until token is refreshed
    gcTime: Infinity,
  });

  // Set up axios interceptor when token changes
  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.access_token) {
      setupAxiosInterceptor(auth.user.access_token);
    } else {
      removeAxiosInterceptor();
    }

    return (): void => {
      removeAxiosInterceptor();
    };
  }, [auth.isAuthenticated, auth.user?.access_token]);

  // Handle token refresh
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const checkTokenExpiration = async (): Promise<void> => {
      if (auth.isAuthenticated && auth.user?.access_token) {
        const decoded = jwtDecode<KeycloakTokenData>(auth.user.access_token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp - currentTime < 60) {
          // Clear any pending refresh
          clearTimeout(timeoutId);

          // Debounce the refresh
          timeoutId = setTimeout(async () => {
            try {
              await auth.signinSilent();
            } catch (error) {
              console.error('Failed to refresh token:', error);
              auth.signoutRedirect();
            }
          }, 500);
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 30000);
    return (): void => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, [auth]);

  return {
    isAuthenticated: auth.isAuthenticated,
    userData,
    login: auth.signinRedirect,
    logout: auth.signoutRedirect,
  };
};

export default useKeycloakAuth;
