import { FormConfig } from 'types/form.types';
import { i131Config } from './I131FormConfig';

const formConfigs: Record<string, FormConfig> = {
  i131v2: i131Config,
};

export const getFormConfig = (formType: string): FormConfig | undefined => {
  return formConfigs[formType?.toLowerCase()];
};
