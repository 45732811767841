/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const DateOfIntendedDeparturePage: React.FC = () => {
  return (
    <Field name="proposedTravel.departureDate">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
        >
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 2,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            What is your date of intended departure?
          </FormLabel>

          <Typography
            variant="body2"
            sx={{
              mb: 1,
              color: 'text.secondary',
              fontSize: '0.875rem',
            }}
          >
            Date (mm/dd/yyyy)
          </Typography>

          <TextField
            {...field}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: '2099-12-31', // Reasonable future date limit
              min: new Date().toISOString().split('T')[0], // Today's date as minimum
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-input': {
                padding: '12px 16px',
              },
            }}
          />

          {meta.touched && meta.error && (
            <Typography
              color="error"
              variant="caption"
              sx={{ mt: 1 }}
            >
              {meta.error}
            </Typography>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default DateOfIntendedDeparturePage;
