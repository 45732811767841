import * as React from 'react';
import BooleanRadioPage from '../SharedPages/BooleanRadio/BooleanRadio';
import { FormPageComponentProps } from 'types/form.types';

const EmploymentAuthorizationPage: React.FC<FormPageComponentProps> = (): JSX.Element => {
  return (
    <BooleanRadioPage
      question="Are you requesting an Employment Authorization Document (EAD) upon approval of your new period of parole (re-parole)?"
      fieldName="eadForNewOrReparole"
    />
  );
};

export default EmploymentAuthorizationPage;
