import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type EthnicitySchemaType = Partial<FormValues> & {
  biographicInfo: {
    ethnicity: string;
  };
};

const EthnicitySchema = createPartialSchema<EthnicitySchemaType>({
  biographicInfo: Yup.object({
    ethnicity: Yup.string().default('').oneOf(['', 'hispanicOrLatino', 'notHispanicOrLatino']),
  }),
});

export default EthnicitySchema;
