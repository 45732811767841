import React from 'react';
import { Typography, Box, keyframes, styled } from '@mui/material';

const loadingAnimation = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;

export const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isBot',
})<{ isBot: boolean }>(({ isBot, theme }) => ({
  maxWidth: '80%',
  padding: '8px 16px',
  backgroundColor: isBot ? theme.palette.grey[100] : theme.palette.primary.main,
  color: isBot ? theme.palette.text.primary : theme.palette.primary.contrastText,
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isBot ? 'flex-start' : 'flex-end',
}));

const LoadingDots: React.FC = () => (
  <Box
    sx={{
      mb: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
  >
    <Typography
      sx={{
        color: '#444',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        mb: 0.5,
      }}
    >
      Chatbot
    </Typography>
    <MessageBubble
      isBot={true}
      data-testid="loading-message-bubble"
      sx={{
        backgroundColor: '#EBEBEB',
        color: '#00476B',
        padding: '12px 16px',
        borderRadius: '4px',
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      {[0, 1, 2].map((index) => (
        <Box
          data-testid="loading-dot"
          key={index}
          sx={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#00476B',
            animation: `${loadingAnimation} 1.4s infinite`,
            animationDelay: `${index * 0.2}s`,
          }}
        />
      ))}
    </MessageBubble>
  </Box>
);

export default LoadingDots;
