import { UseMutationResult } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { SaveFormDataPayload } from 'services/formDataService';
import { FormValues, FormConfig, FormDataError } from 'types/form.types';
import { StoredFormData } from 'types/services.types';

export const useBeforeUnloadSave = (
  formikRef: React.RefObject<FormikProps<FormValues>>,
  formConfig: FormConfig | undefined,
  formType: string | undefined,
  saveFormMutation: UseMutationResult<StoredFormData, FormDataError, SaveFormDataPayload>
): void => {
  useEffect(() => {
    if (!formConfig?.formId) return;

    const handleBeforeUnload = async (): Promise<void> => {
      if (formikRef.current?.values) {
        try {
          await saveFormMutation.mutateAsync({
            id: formConfig.formId,
            values: formikRef.current.values,
            formType: formType || '',
            lastUpdated: new Date().toISOString(),
          });
        } catch (error) {
          console.error('Failed to save form data on unload:', error);
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formConfig?.formId, formType, saveFormMutation, formikRef]);
};
