import * as Yup from 'yup';
import {
  AdvanceParoleSchema,
  ApplicationTypeSchema,
  createPartialSchema,
  I94InfoSchema,
  OutsideInitialParoleSchema,
  SelfOtherInUsInitParoleSchema,
  SelfOtherRenewParoleSchema,
  TPSBeneficiarySchema,
} from 'types/form.types';

// Application Type Schema
export const applicationTypeSelectSchema = createPartialSchema<ApplicationTypeSchema>({
  formStatus: Yup.string().defined(),
  lastUpdated: Yup.string().defined(),
  applicationType: Yup.string().required('Please select an application type'),
  refugeeOrLprRefugee: Yup.string().required('Please select Yes or No'),
});

// TPS Beneficiary Schema
export const tpsBeneficiarySchema = createPartialSchema<TPSBeneficiarySchema>({
  applicationType: Yup.string().oneOf(['4']),
  tpsBeneficiary: Yup.object({
    tpsReceiptNumber: Yup.string().required('Receipt number is required when selecting TPS'),
  }),
});

// Advance Parole Schema
export const advanceParoleSchema = createPartialSchema<AdvanceParoleSchema>({
  applicationType: Yup.string().oneOf(['5']),
  inUsAdvanceParoleDocument: Yup.object({
    program: Yup.string().required('Please select a program'),
    receiptNumber: Yup.string().when('program', {
      is: (value: string) => value && value !== 'D',
      then: () => Yup.string().required('Please enter a value'),
    }),
  }),
});

// Outside Initial Parole Schema
export const outsideInitialParoleSchema = createPartialSchema<OutsideInitialParoleSchema>({
  applicationType: Yup.string().oneOf(['6']),
  outsideUsInitParoleDocument: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPerson: Yup.string().when('program', {
      is: 'B',
      then: () => Yup.string().required('Please select a category'),
    }),
    receiptNumber: Yup.string().when('program', {
      is: (value: string) => ['A', 'D'].includes(value),
      then: () => Yup.string().required('Receipt number is required'),
    }),
    govExecBranch: Yup.string().when('program', {
      is: 'C',
      then: () => Yup.string().required('U.S. Federal Executive Branch Government Agency is required'),
    }),
    repEmailAddress: Yup.string().when('program', {
      is: 'C',
      then: () =>
        Yup.string()
          .email('Invalid email address')
          .required('U.S. Federal Government Agency Representative Official Email Address is required'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'E',
      then: () => Yup.string().required('Please list specific parole program or process'),
    }),
  }),
});

// Self/Other In US Initial Parole Schema
export const selfOtherInUsInitParoleSchema = createPartialSchema<SelfOtherInUsInitParoleSchema>({
  applicationType: Yup.string().oneOf(['8']),
  selfOrOtherInUsInitParole: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPersonStatus: Yup.string().when('program', {
      is: 'A',
      then: () => Yup.string().required('Please select a status'),
    }),
    receiptNumber: Yup.string().when('program', {
      is: 'B',
      then: () => Yup.string().required('Task Force Registration Number is required'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'C',
      then: () => Yup.string().required('Please list specific program or process'),
    }),
  }),
});

// Self/Other Renew Parole Schema
export const selfOtherRenewParoleSchema = createPartialSchema<SelfOtherRenewParoleSchema>({
  applicationType: Yup.string().oneOf(['10']),
  selfOrOtherRenewParole: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPerson: Yup.string().when('program', {
      is: 'E',
      then: () => Yup.string().required('Please select a status'),
    }),
    forPersonStatus: Yup.string().when('program', {
      is: 'H',
      then: () => Yup.string().required('Please select a status'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'I',
      then: () => Yup.string().required('Please list specific program or process'),
    }),
  }),
});

// I-94 Info Schema
export const i94InfoSchema = createPartialSchema<I94InfoSchema>({
  applicationType: Yup.string().oneOf(['10', '11']),
  i94AdmittedDate: Yup.string().required('Until Date/Parole shown on Form I-94 is required'),
});

// Remove the DeepPartial type and make the schema match FormValues exactly
// const i131ApplicationTypeSchema = Yup.object()
//   .shape({
//     formStatus: Yup.string().required(),
//     lastUpdated: Yup.string().required(),
//     applicationType: Yup.string().required('Please select an application type'),
//     i94AdmittedDate: Yup.string().defined(),
//     refugeeOrLprRefugee: Yup.string().defined(),

//     tpsBeneficiary: Yup.object({
//       tpsReceiptNumber: Yup.string().defined(),
//     })
//       .when('applicationType', {
//         is: '4',
//         then: (schema) =>
//           schema.shape({
//             tpsReceiptNumber: Yup.string().required('Receipt number is required when selecting TPS'),
//           }),
//       })
//       .defined(),

//     inUsAdvanceParoleDocument: Yup.object({
//       program: Yup.string().defined(),
//       receiptNumber: Yup.string().defined(),
//     })
//       .when('applicationType', {
//         is: '5',
//         then: (schema) =>
//           schema.shape({
//             program: Yup.string().required('Please select a program'),
//             receiptNumber: Yup.string().when('program', {
//               is: (value: string) => value && value !== 'D',
//               then: () => Yup.string().required('Please enter a value'),
//             }),
//           }),
//       })
//       .defined(),

//     outsideUsInitParoleDocument: Yup.object({
//       program: Yup.string().defined(),
//       forPerson: Yup.string().defined(),
//       receiptNumber: Yup.string().defined(),
//       govExecBranch: Yup.string().defined(),
//       repEmailAddress: Yup.string().defined(),
//       otherProgram: Yup.string().defined(),
//     })
//       .when('applicationType', {
//         is: '6',
//         then: (schema) =>
//           schema.shape({
//             program: Yup.string().required('Please select a program'),
//             forPerson: Yup.string().when('program', {
//               is: 'B',
//               then: () => Yup.string().required('Please select a category'),
//             }),
//             receiptNumber: Yup.string().when('program', {
//               is: (value: string) => ['A', 'D'].includes(value),
//               then: () => Yup.string().required('Receipt number is required'),
//             }),
//             govExecBranch: Yup.string().when('program', {
//               is: 'C',
//               then: () => Yup.string().required('U.S. Federal Executive Branch Government Agency is required'),
//             }),
//             repEmailAddress: Yup.string().when('program', {
//               is: 'C',
//               then: () =>
//                 Yup.string()
//                   .email('Invalid email address')
//                   .required('U.S. Federal Government Agency Representative Official Email Address is required'),
//             }),
//             otherProgram: Yup.string().when('program', {
//               is: 'E',
//               then: () => Yup.string().required('Please list specific parole program or process'),
//             }),
//           }),
//       })
//       .defined(),

//     selfOrOtherInUsInitParole: Yup.object({
//       program: Yup.string().defined(),
//       forPersonStatus: Yup.string().defined(),
//       receiptNumber: Yup.string().defined(),
//       otherProgram: Yup.string().defined(),
//     })
//       .when('applicationType', {
//         is: '8',
//         then: (schema) =>
//           schema.shape({
//             program: Yup.string().required('Please select a program'),
//             forPersonStatus: Yup.string().when('program', {
//               is: 'A',
//               then: () => Yup.string().required('Please select a status'),
//             }),
//             receiptNumber: Yup.string().when('program', {
//               is: 'B',
//               then: () => Yup.string().required('Task Force Registration Number is required'),
//             }),
//             otherProgram: Yup.string().when('program', {
//               is: 'C',
//               then: () => Yup.string().required('Please list specific program or process'),
//             }),
//           }),
//       })
//       .defined(),

//     selfOrOtherRenewParole: Yup.object({
//       program: Yup.string().defined(),
//       forPerson: Yup.string().defined(),
//       forPersonStatus: Yup.string().defined(),
//       otherProgram: Yup.string().defined(),
//     })
//       .when('applicationType', {
//         is: '10',
//         then: (schema) =>
//           schema.shape({
//             program: Yup.string().required('Please select a program'),
//             forPerson: Yup.string().when('program', {
//               is: 'E',
//               then: () => Yup.string().required('Please select a status'),
//             }),
//             forPersonStatus: Yup.string().when('program', {
//               is: 'H',
//               then: () => Yup.string().required('Please select a status'),
//             }),
//             otherProgram: Yup.string().when('program', {
//               is: 'I',
//               then: () => Yup.string().required('Please list specific program or process'),
//             }),
//           }),
//       })
//       .defined(),
//   })
//   .test('conditional-validation', '', function (value) {
//     if (value.applicationType === '10' || value.applicationType === '11') {
//       if (!value.i94AdmittedDate) {
//         return this.createError({
//           path: 'i94AdmittedDate',
//           message: 'Until Date/Parole shown on Form I-94 is required',
//         });
//       }
//     }

//     if (!value.refugeeOrLprRefugee) {
//       return this.createError({
//         path: 'refugeeOrLprRefugee',
//         message: 'Please select Yes or No',
//       });
//     }

//     return true;
//   }) as Yup.ObjectSchema<FormValues>;

// export default i131ApplicationTypeSchema;
