/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import BooleanRadio from '../../SharedPages/BooleanRadio/BooleanRadio';

const HasPreparerPage: React.FC = () => {
  return (
    <BooleanRadio
      question="Did you use a preparer for this application?"
      fieldName="preparerContactAndCertification.hasPreparer"
    />
  );
};

export default HasPreparerPage;
