import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type EyeColorSchemaType = Partial<FormValues> & {
  biographicInfo: {
    eyeColor: string;
  };
};

const EyeColorSchema = createPartialSchema<EyeColorSchemaType>({
  biographicInfo: Yup.object({
    eyeColor: Yup.string()
      .default('')
      .oneOf(['', 'Black', 'Blue', 'Brown', 'Gray', 'Green', 'Hazel', 'Maroon', 'Pink', 'Unknown']),
  }),
});

export default EyeColorSchema;
