import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type LengthOfTripSchemaType = Partial<FormValues> & {
  proposedTravel: {
    numberOfTrips: string;
    expectedLength: string;
  };
};

const LengthOfTripSchema = createPartialSchema<LengthOfTripSchemaType>({
  proposedTravel: Yup.object({
    numberOfTrips: Yup.string().default('').oneOf(['', 'oneTrip', 'moreThanOneTrip']),
    expectedLength: Yup.string().default(''),
  }),
});

export default LengthOfTripSchema;
