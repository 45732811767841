import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type ReturnToCountrySchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    returnToCountry: BooleanSchema;
    returnToCountryExplanation: string;
  };
};

const returnToCountrySchema = createPartialSchema<ReturnToCountrySchemaType>({
  biographicInfo: Yup.object({
    returnToCountry: booleanSchema,
    returnToCountryExplanation: Yup.string().default(''),
  }),
});

export default returnToCountrySchema;
