import { useState, useEffect, useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FormValues, SubSection } from 'types/form.types';

interface ValidationState {
  isValid: boolean;
  isSubmitting: boolean;
  isValidating: boolean;
  validationError?: string | null;
}

interface YupValidationError extends Error {
  inner: Array<{
    path: string;
    message: string;
  }>;
}

// Helper to get relevant validation fields based on subsection ID
const getValidationFields = (subsection: SubSection, values: FormValues): Record<string, unknown> => {
  // Base fields object now includes any common fields
  const baseFields: Record<string, unknown> = {};

  switch (subsection.id) {
    case 'select-type':
      return {
        formStatus: values.formStatus,
        lastUpdated: values.lastUpdated,
        applicationType: values.applicationType,
        refugeeOrLprRefugee: values.refugeeOrLprRefugee,
      };

    case 'renew-parole':
      return {
        applicationType: values.applicationType,
        selfOrOtherRenewParole: values.selfOrOtherRenewParole,
        ...(values.selfOrOtherRenewParole?.program === 'E' && {
          forPerson: values.selfOrOtherRenewParole?.forPerson,
        }),
        ...(values.selfOrOtherRenewParole?.program === 'H' && {
          forPersonStatus: values.selfOrOtherRenewParole?.forPersonStatus,
        }),
        ...(values.selfOrOtherRenewParole?.program === 'I' && {
          otherProgram: values.selfOrOtherRenewParole?.otherProgram,
        }),
      };

    case 'i94-info':
      return {
        applicationType: values.applicationType,
        i94AdmittedDate: values.i94AdmittedDate,
      };

    case 'tps-beneficiary':
      return {
        applicationType: values.applicationType,
        tpsBeneficiary: values.tpsBeneficiary,
      };

    case 'advance-parole':
      return {
        applicationType: values.applicationType,
        inUsAdvanceParoleDocument: values.inUsAdvanceParoleDocument,
      };

    case 'outside-us-parole':
      return {
        applicationType: values.applicationType,
        outsideUsInitParoleDocument: values.outsideUsInitParoleDocument,
      };

    case 'self-other-parole':
      return {
        applicationType: values.applicationType,
        selfOrOtherInUsInitParole: values.selfOrOtherInUsInitParole,
      };

    case 'ead-request-page':
      return {
        eadForNewOrReparole: values.eadForNewOrReparole,
      };

    case 'applicant-contact-information-page':
      return {
        applicantContactAndCertification: {
          daytimePhone: values.applicantContactAndCertification.daytimePhone,
          mobilePhone: values.applicantContactAndCertification.mobilePhone,
          email: values.applicantContactAndCertification.email,
        },
      };

    case 'applicant-certification-signature-page':
      return {
        applicantContactAndCertification: {
          signature: values.applicantContactAndCertification.signature,
          dateOfSignature: values.applicantContactAndCertification.dateOfSignature,
        },
      };

    case 'has-interpreter-page':
      return {
        interpreterContactAndCertification: {
          hasInterpreter: values.interpreterContactAndCertification.hasInterpreter,
        },
      };

    case 'interpreter-name-page':
      return {
        interpreterContactAndCertification: {
          fullName: {
            familyName: values.interpreterContactAndCertification.fullName.familyName,
            givenName: values.interpreterContactAndCertification.fullName.givenName,
          },
          businessName: values.interpreterContactAndCertification.businessName,
        },
      };

    case 'interpreter-contact-information-page':
      return {
        interpreterContactAndCertification: {
          daytimePhone: values.interpreterContactAndCertification.daytimePhone,
          mobilePhone: values.interpreterContactAndCertification.mobilePhone,
          email: values.interpreterContactAndCertification.email,
        },
      };

    case 'interpreter-certification-signature-page':
      return {
        interpreterContactAndCertification: {
          language: values.interpreterContactAndCertification.language,
          signature: values.interpreterContactAndCertification.signature,
          dateOfSignature: values.interpreterContactAndCertification.dateOfSignature,
        },
      };

    case 'has-preparer-page':
      return {
        preparerContactAndCertification: {
          hasPreparer: values.preparerContactAndCertification.hasPreparer,
        },
      };

    case 'preparer-name-page':
      return {
        preparerContactAndCertification: {
          fullName: {
            familyName: values.preparerContactAndCertification.fullName.familyName,
            givenName: values.preparerContactAndCertification.fullName.givenName,
          },
          businessName: values.preparerContactAndCertification.businessName,
        },
      };

    case 'preparer-contact-information-page':
      return {
        preparerContactAndCertification: {
          daytimePhone: values.preparerContactAndCertification.daytimePhone,
          mobilePhone: values.preparerContactAndCertification.mobilePhone,
          email: values.preparerContactAndCertification.email,
        },
      };

    case 'preparer-certification-signature-page':
      return {
        preparerContactAndCertification: {
          signature: values.preparerContactAndCertification.signature,
          dateOfSignature: values.preparerContactAndCertification.dateOfSignature,
        },
      };
    case 'parole-qualification-page':
      return {
        initPipReparole: {
          qualification: values.initPipReparole.qualification,
        },
      };
    case 'length-of-stay-page':
      return {
        initPipReparole: {
          lengthOfStayInUs: values.initPipReparole.lengthOfStayInUs,
        },
      };
    case 'intended-recipient-page':
      return {
        initPipReparole: {
          intendedRecipientOutsideOfUS: values.initPipReparole.intendedRecipientOutsideOfUS,
          dateOfIntendedArrival: values.initPipReparole.dateOfIntendedArrival,
          locationToNotify: {
            cityOrTown: values.initPipReparole.locationToNotify.cityOrTown,
            country: values.initPipReparole.locationToNotify.country,
          },
        },
      };

    case 'date-of-intended-departure-page':
      return {
        proposedTravel: {
          departureDate: values.proposedTravel.departureDate,
        },
      };
    case 'purpose-of-trip-page':
      return {
        proposedTravel: {
          purpose: values.proposedTravel.purpose,
        },
      };

    case 'intended-countries-page':
      return {
        proposedTravel: {
          countriesIntendedToVisit: values.proposedTravel.countriesIntendedToVisit,
        },
      };

    case 'length-of-trip-page':
      return {
        proposedTravel: {
          numberOfTrips: values.proposedTravel.expectedLength,
          expectedLength: values.proposedTravel.expectedLength,
        },
      };

    case 'reentry-permit-information-page':
      return {
        totalTimeOutsideUs: values.totalTimeOutsideUs,
      };

    case 'previous-proceedings-page':
      return {
        processingInfo: {
          previousProceedings: values.processingInfo.previousProceedings,
        },
      };

    case 'previous-reentry-page':
      return {
        processingInfo: {
          previousReentryPermit: {
            issued: values.processingInfo.previousReentryPermit.issued,
            dateIssued: values.processingInfo.previousReentryPermit.dateIssued,
            disposition: values.processingInfo.previousReentryPermit.disposition,
          },
        },
      };

    case 'previous-advanced-parole-page':
      return {
        processingInfo: {
          previousAdvanceParole: {
            issued: values.processingInfo.previousAdvanceParole.issued,
            dateIssued: values.processingInfo.previousAdvanceParole.dateIssued,
            disposition: values.processingInfo.previousAdvanceParole.disposition,
          },
        },
      };

    case 'replacement-document-page':
      return {
        processingInfo: {
          requestReplacement: values.processingInfo.requestReplacement,
          reasonForReplacement: values.processingInfo.reasonForReplacement,
          infoCorrection: values.processingInfo.infoCorrection,
          infoCorrectionExplanation: values.processingInfo.infoCorrectionExplanation,
          replacementReceiptNumber: values.processingInfo.replacementReceiptNumber,
        },
      };

    case 'send-documents-page':
      return {
        processingInfo: {
          documentSendTo: {
            toUsAddressOrEmbassy: values.processingInfo.documentSendTo.toUsAddressOrEmbassy,
            embassyCityOrTown: values.processingInfo.documentSendTo.embassyCityOrTown,
            embassyCountry: values.processingInfo.documentSendTo.embassyCountry,
          },
          noticeSendTo: {
            selectDestination: values.processingInfo.noticeSendTo.selectDestination,
            address: values.processingInfo.noticeSendTo.address,
            daytimePhone: values.processingInfo.noticeSendTo.daytimePhone,
            emailAddress: values.processingInfo.noticeSendTo.emailAddress,
          },
        },
      };

    case 'ethnicity-page':
      return {
        biographicInfo: {
          ethnicity: values.biographicInfo.ethnicity,
        },
      };

    case 'race-page':
      return {
        biographicInfo: {
          race: values.biographicInfo.race,
        },
      };

    case 'height-weight-page':
      return {
        biographicInfo: {
          height: {
            feet: values.biographicInfo.height.feet,
            inches: values.biographicInfo.height.inches,
          },
          weight: values.biographicInfo.weight,
        },
      };

    case 'eye-color-page':
      return {
        biographicInfo: {
          eyeColor: values.biographicInfo.eyeColor,
        },
      };

    case 'hair-color-page':
      return {
        biographicInfo: {
          hairColor: values.biographicInfo.hairColor,
        },
      };

    case 'refugee-country-page':
      return {
        refugeeTravelDocumentInfo: {
          originCountry: values.refugeeTravelDocumentInfo.originCountry,
        },
      };

    case 'plan-to-travel-back-page':
      return {
        refugeeTravelDocumentInfo: {
          planToTravelBack: values.refugeeTravelDocumentInfo.planToTravelBack,
          planToTravelBackExplanation: values.refugeeTravelDocumentInfo.planToTravelBackExplanation,
        },
      };

    case 'returned-to-country-page':
      return {
        refugeeTravelDocumentInfo: {
          returnToCountry: values.refugeeTravelDocumentInfo.returnToCountry,
          returnToCountryExplanation: values.refugeeTravelDocumentInfo.returnToCountryExplanation,
        },
      };

    case 'applied-for-passport-page':
      return {
        refugeeTravelDocumentInfo: {
          appliedForPassport: values.refugeeTravelDocumentInfo.appliedForPassport,
          appliedForPassportExplanation: values.refugeeTravelDocumentInfo.appliedForPassportExplanation,
        },
      };

    case 'recieved-benefits-page':
      return {
        refugeeTravelDocumentInfo: {
          receiveBenefits: values.refugeeTravelDocumentInfo.receiveBenefits,
          receiveBenefitsExplanation: values.refugeeTravelDocumentInfo.receiveBenefitsExplanation,
        },
      };

    case 'reacquired-nationality-page':
      return {
        refugeeTravelDocumentInfo: {
          reacquiredNationality: values.refugeeTravelDocumentInfo.reacquiredNationality,
          reacquiredNationalityExplanation: values.refugeeTravelDocumentInfo.reacquiredNationalityExplanation,
        },
      };

    case 'new-nationality-page':
      return {
        refugeeTravelDocumentInfo: {
          acquiredNewNationality: values.refugeeTravelDocumentInfo.acquiredNewNationality,
          acquiredNewNationalityExplanation: values.refugeeTravelDocumentInfo.acquiredNewNationalityExplanation,
        },
      };

    case 'granted-asylum-in-other-country-page':
      return {
        refugeeTravelDocumentInfo: {
          grantedAsyleeForOtherCountry: values.refugeeTravelDocumentInfo.grantedAsyleeForOtherCountry,
          grantedAsyleeForOtherCountryExplanation:
            values.refugeeTravelDocumentInfo.grantedAsyleeForOtherCountryExplanation,
        },
      };

    default: {
      // For any fields that don't need special handling, include them directly
      const relevantValue = values[subsection.id as keyof FormValues];
      if (relevantValue !== undefined) {
        baseFields[subsection.id] = relevantValue;
      }
      return baseFields;
    }
  }
};

export const useFormSectionValidation = (
  getCurrentSubSection: () => SubSection | null,
  options: {
    debug?: boolean;
    validationDelay?: number;
  } = {}
): ValidationState => {
  const { debug = false, validationDelay = 100 } = options;

  const { values, isSubmitting, isValidating, setFieldError } = useFormikContext<FormValues>();

  const [validationState, setValidationState] = useState<ValidationState>({
    isValid: true,
    isSubmitting: false,
    isValidating: false,
    validationError: null,
  });

  const timerRef = useRef<NodeJS.Timeout>();

  const validateCurrentSection = useCallback(async () => {
    const currentSubSection = getCurrentSubSection();

    if (!currentSubSection?.validation) {
      if (debug) {
        console.debug('[Validation] No validation schema found for current subsection');
      }
      setValidationState((prev) => ({
        ...prev,
        isValid: true,
        validationError: null,
      }));
      return;
    }

    try {
      const relevantValues = getValidationFields(currentSubSection, values);

      if (debug) {
        console.debug('[Validation] Starting validation for:', {
          subsectionId: currentSubSection.id,
          relevantValues,
        });
      }

      await currentSubSection.validation.validate(relevantValues, {
        abortEarly: false,
        strict: false,
      });

      if (debug) {
        console.debug('[Validation] Validation successful');
      }

      setValidationState((prev) => ({
        ...prev,
        isValid: true,
        validationError: null,
      }));
    } catch (error) {
      if (debug) {
        console.error('[Validation] Validation failed:', error);
      }

      const errorMessage = error instanceof Error ? error.message : 'Validation failed';

      setValidationState((prev) => ({
        ...prev,
        isValid: false,
        validationError: errorMessage,
      }));

      // Set field-specific errors if available
      if (error instanceof Error && 'inner' in error) {
        const yupError = error as YupValidationError;
        yupError.inner.forEach(({ path, message }) => {
          setFieldError(path, message);
        });
      }
    }
  }, [getCurrentSubSection, values, debug, setFieldError]);

  // Effect to trigger validation
  useEffect(() => {
    // Skip validation if already validating or submitting
    if (isValidating || isSubmitting) {
      if (debug) {
        console.debug('[Validation] Skipping validation - already validating/submitting');
      }
      return;
    }

    // Update submitting/validating state without triggering a validation
    if (validationState.isSubmitting !== isSubmitting || validationState.isValidating !== isValidating) {
      setValidationState((prev) => ({
        ...prev,
        isSubmitting,
        isValidating,
      }));
    }

    // Clear existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set new timer for validation
    timerRef.current = setTimeout(validateCurrentSection, validationDelay);

    // Cleanup
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [
    validateCurrentSection,
    isValidating,
    isSubmitting,
    validationDelay,
    debug,
    validationState.isSubmitting,
    validationState.isValidating,
  ]);

  // Effect to clear validation error when subsection changes
  useEffect(() => {
    const currentSubSection = getCurrentSubSection();
    if (currentSubSection) {
      setValidationState((prev) => ({
        ...prev,
        validationError: null,
      }));
    }
  }, [getCurrentSubSection]);

  return validationState;
};

export default useFormSectionValidation;
