/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, FormHelperText } from '@mui/material';

const timeOutsideOptions = [
  { value: 'lessThan6Months', label: 'Less Than 6 Months' },
  { value: 'sixMonthsToOneYear', label: '6 Months to 1 Year' },
  { value: 'oneToTwoYears', label: '1 to 2 Years' },
  { value: 'twoToThreeYears', label: '2 to 3 Years' },
  { value: 'threeToFourYears', label: '3 to 4 Years' },
  { value: 'moreThanFourYears', label: 'More Than 4 Years' },
];

const ReentryPermitInformationPage: React.FC = () => {
  return (
    <Field name="totalTimeOutsideUs">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          component="fieldset"
          error={meta.touched && !!meta.error}
          required
          sx={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 2,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            Since becoming a permanent resident of the United States (or during the past 5 years, whichever is less),
            how much total time have you spent outside the United States?
          </FormLabel>

          <RadioGroup
            {...field}
            sx={{
              '& .MuiRadio-root': {
                padding: '6px 9px',
              },
            }}
          >
            {timeOutsideOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  marginY: 0.5,
                  '& .MuiFormControlLabel-label': {
                    fontSize: '1rem',
                    color: 'text.primary',
                  },
                }}
              />
            ))}
          </RadioGroup>

          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default ReentryPermitInformationPage;
