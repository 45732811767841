import { checkDate } from '../../../utils/pureFunctions';
import { booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';
import { createPartialSchema, FormValues } from '../../../types/form.types';

type previousAdvanceParoleSchemaType = Partial<FormValues> & {
  processingInfo: {
    previousAdvanceParole: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
  };
};

const previousAdvanceParoleSchema = createPartialSchema<previousAdvanceParoleSchemaType>({
  processingInfo: Yup.object({
    previousAdvanceParole: Yup.object({
      issued: booleanSchema,
      dateIssued: Yup.string()
        .default('')
        .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
      disposition: Yup.string().default(''),
    }),
  }),
});
export type PreviousAdvanceParoleSchema = Yup.InferType<typeof previousAdvanceParoleSchema>;

export default previousAdvanceParoleSchema;
