/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Field } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import BooleanRadioPage from '../../SharedPages/BooleanRadio/BooleanRadio';
import { InfoCorrectionType } from '../../../utils/I131Json.schema';

const reasonForReplacementOptions = [
  { value: 'notReceive', label: 'My document was issued, but I did not receive it.' },
  { value: 'stolenOrDamage', label: 'I received my document, but then it was lost, stolen, or damaged.' },
  {
    value: 'informationErrorCausedByMe',
    label:
      'I received my document, but it has incorrect information because of an error caused by me or because my information has changed.',
  },
  {
    value: 'informationErrorCausedByUSCIS',
    label:
      'I received my document, but it has incorrect information because of an error not caused by me (such as a U.S. Citizenship and Immigration Services (USCIS) error).',
  },
];

const validInfoCorrectionValue = [
  { label: 'Name', value: 'name' },
  { label: 'Alien Number', value: 'aNumber' },
  { label: 'Country of Birth', value: 'countryOfBirth' },
  { label: 'Terms and Conditions', value: 'termsAndConditions' },
  { label: 'Date of Birth', value: 'dateOfBirth' },
  { label: 'Gender', value: 'gender' },
  { label: 'Validity Date', value: 'validityDate' },
  { label: 'Photo', value: 'photo' },
] as const;

const RequestReplacementPage: React.FC = () => {
  return (
    <>
      <BooleanRadioPage
        question="Are you requesting a replacement Reentry Permit, Refugee Travel Document, Advance Parole Document, or TPS Travel Authorization Document?"
        fieldName="processingInfo.requestReplacement"
        required
      />

      <Field name="processingInfo.reasonForReplacement">
        {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
            required
            sx={{ width: '100%' }}
          >
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 1,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              Why are you requesting a replacement?
            </FormLabel>

            <RadioGroup
              {...field}
              onChange={(e): void => {
                form.setFieldValue('processingInfo.reasonForReplacement', e.target.value);
              }}
            >
              {reasonForReplacementOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={field.value === option.value} />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
            {meta.touched && meta.error && (
              <Typography
                color="error"
                variant="caption"
                sx={{ mt: 1 }}
              >
                {meta.error}
              </Typography>
            )}
          </FormControl>
        )}
      </Field>

      <Field name="processingInfo.infoCorrection">
        {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
            required
            sx={{ width: '100%' }}
          >
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 1,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              Please select the applicable box(es) indicating the information that needs to be corrected and then
              provide any additional information in the text box that helps USCIS confirm the correction needed.
            </FormLabel>

            <FormGroup>
              {validInfoCorrectionValue.map((info) => (
                <FormControlLabel
                  key={info.value}
                  value={info.value}
                  control={
                    <Checkbox
                      checked={field.value.includes(info.value)}
                      onChange={(): void => {
                        const prev = field.value;
                        const curr = prev.includes(info.value)
                          ? prev.filter((item: InfoCorrectionType) => item !== info.value)
                          : [...prev, info.value];
                        form.setFieldValue(
                          field.name,
                          curr.filter((v: string) => !!v)
                        );
                      }}
                    />
                  }
                  label={info.label}
                />
              ))}
            </FormGroup>
            {meta.touched && meta.error && (
              <Typography
                color="error"
                variant="caption"
                sx={{ mt: 1 }}
              >
                {meta.error}
              </Typography>
            )}
          </FormControl>
        )}
      </Field>

      <Field name="processingInfo.infoCorrectionExplanation">
        {({ field }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl fullWidth>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Provide an explanation of what is incorrect on your current document to support your request for a
              correction and attach copies of any documents supporting your request.
            </Typography>
            <TextField
              {...field}
              multiline
              rows={4}
              inputProps={{
                maxLength: 1000,
              }}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E0E0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#BDBDBD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Maximum character limit is 1000.
            </Typography>

            {field.value && (
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  color: 'text.secondary',
                }}
              >
                {field.value.length}/1000 characters
              </Typography>
            )}
          </FormControl>
        )}
      </Field>

      <Field name="processingInfo.replacementReceiptNumber">
        {({ field }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl fullWidth>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
                marginTop: '16px',
              }}
            >
              Provide the receipt number for the Form I-131 related to the Reentry Permit, Refugee Travel Document,
              Advance Parole Document, or TPS Travel Authorization Document that you are seeking to replace:
            </Typography>
            <TextField
              {...field}
              label="Replacement receipt number"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
            />
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default RequestReplacementPage;
