import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type PreparerNameSchemaType = Partial<FormValues> & {
  preparerContactAndCertification: {
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
  };
};

const PreparerNameSchema = createPartialSchema<PreparerNameSchemaType>({
  preparerContactAndCertification: Yup.object({
    fullName: Yup.object({
      familyName: Yup.string().default(''),
      givenName: Yup.string().default(''),
    }),
    businessName: Yup.string().default(''),
  }),
});

export default PreparerNameSchema;
