import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type HeightWeightSchemaType = Partial<FormValues> & {
  biographicInfo: {
    height: {
      feet: string;
      inches: string;
    };
    weight: string;
  };
};

const HeightWeightSchema = createPartialSchema<HeightWeightSchemaType>({
  biographicInfo: Yup.object({
    height: Yup.object({
      feet: Yup.string().default(''),
      inches: Yup.string().default(''),
    }),
    weight: Yup.string().default(''),
  }),
});

export default HeightWeightSchema;
