import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type CountryOriginSelectSchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    originCountry: string;
  };
};

const countryOriginSelectSchema = createPartialSchema<CountryOriginSelectSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    originCountry: Yup.string().default(''),
  }),
});

export default countryOriginSelectSchema;
