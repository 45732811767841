/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, MenuItem, Select, FormHelperText, Typography } from '@mui/material';

const eyeColorOptions = [
  { value: 'Black', label: 'Black' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Green', label: 'Green' },
  { value: 'Hazel', label: 'Hazel' },
  { value: 'Maroon', label: 'Maroon' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Unknown', label: 'Unknown' },
];

const EyeColorPage: React.FC = () => {
  return (
    <Field name="biographicInfo.eyeColor">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
          required
          sx={{ width: '100%' }}
        >
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 1,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            What is the eye color of the person who will receive the document?
          </FormLabel>

          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: 'text.secondary',
              fontSize: '0.875rem',
            }}
          >
            Only select one option
          </Typography>

          <Select
            {...field}
            displayEmpty
            sx={{
              mt: 1,
              backgroundColor: 'background.paper',
              '& .MuiSelect-select': {
                padding: '12px 16px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BDBDBD',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              <em>Select eye color</em>
            </MenuItem>
            {eyeColorOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: '1rem',
                  '&.Mui-selected': {
                    backgroundColor: '#EBF3FF',
                    '&:hover': {
                      backgroundColor: '#E3EDF9',
                    },
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default EyeColorPage;
