import React from 'react';
import { Field } from 'formik';
import { TextField, Box, Typography } from '@mui/material';
import { FieldRenderProps, FormPageComponentProps } from 'types/form.types';

const TPSBeneficiaryInfo: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { touched, errors } = formikProps;

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        TPS Beneficiary Information
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Please provide your TPS information below.
      </Typography>

      <Field name="tpsBeneficiary.tpsReceiptNumber">
        {({ field }: FieldRenderProps): JSX.Element => (
          <TextField
            {...field}
            label="I-821 Receipt Number"
            variant="outlined"
            fullWidth
            margin="normal"
            error={touched.tpsBeneficiary?.tpsReceiptNumber && Boolean(errors?.tpsBeneficiary?.tpsReceiptNumber)}
            helperText={touched.tpsBeneficiary?.tpsReceiptNumber && errors?.tpsBeneficiary?.tpsReceiptNumber}
          />
        )}
      </Field>
    </Box>
  );
};

export default TPSBeneficiaryInfo;
