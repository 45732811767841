import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type AcquiredNewNationalitySchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    acquiredNewNationality: BooleanSchema;
    acquiredNewNationalityExplanation: string;
  };
};

const acquiredNewNationalitySchema = createPartialSchema<AcquiredNewNationalitySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    acquiredNewNationality: booleanSchema,
    acquiredNewNationalityExplanation: Yup.string().default(''),
  }),
});

export default acquiredNewNationalitySchema;
