import * as React from 'react';
import ContactInformationPage from '../../SharedPages/ContactInformationPage/ContactInformationPage';

const InterpreterContactInformationPage: React.FC = () => {
  return (
    <ContactInformationPage
      question="Interpreter's Contact Information"
      daytimePhoneLabel="Interpreter's Daytime Telephone Number"
      mobilePhoneLabel="Interpreter's Mobile Telephone Number (if any)"
      emailLabel="Interpreter's Email Address (if any)"
      daytimePhonePath="interpreterContactAndCertification.daytimePhone"
      mobilePhonePath="interpreterContactAndCertification.mobilePhone"
      emailPath="interpreterContactAndCertification.email"
    />
  );
};

export default InterpreterContactInformationPage;
