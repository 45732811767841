import { UseQueryOptions } from '@tanstack/react-query';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

export type ApplicationType = '' | 'reentry' | 'refugee' | 'advance';

export interface BaseFormValues {
  formStatus?: string;
  lastUpdated?: string;
}

// Do not be too specific if you want this to be more generic
export interface FormValues extends BaseFormValues {
  applicationType: string;
  tpsBeneficiary: {
    tpsReceiptNumber: string;
  };
  inUsAdvanceParoleDocument: {
    program: string;
    receiptNumber: string;
  };
  outsideUsInitParoleDocument: {
    program: string;
    forPerson: string;
    receiptNumber: string;
    govExecBranch: string;
    repEmailAddress: string;
    otherProgram: string;
  };
  selfOrOtherInUsInitParole: {
    program: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
  selfOrOtherRenewParole: {
    program: string;
    forPerson: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
  i94AdmittedDate: string;
  refugeeOrLprRefugee: string;
  biographicInfo: {
    ethnicity: string;
    race: string[];
    height: {
      feet: string;
      inches: string;
    };
    weight: string;
    eyeColor: string;
    hairColor: string;
  };
  processingInfo: {
    previousProceedings: string;
    previousReentryPermit: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
    previousAdvanceParole: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
    requestReplacement: string;
    reasonForReplacement: string;
    infoCorrection: string[];
    infoCorrectionExplanation: string;
    replacementReceiptNumber: string;
    documentSendTo: {
      toUsAddressOrEmbassy: string;
      embassyCityOrTown: string;
      embassyCountry: string;
    };
    noticeSendTo: {
      selectDestination: string;
      address: {
        inCareOfName: string;
        street: string;
        unitType: string;
        unitNumber: string;
        cityOrTown: string;
        state: string;
        zipCode: string;
        province: string;
        postalCode: string;
        country: string;
      };
      daytimePhone: string;
      emailAddress: string;
    };
  };
  refugeeTravelDocumentInfo: {
    originCountry: string;
    planToTravelBack: string;
    planToTravelBackExplanation: string;
    returnToCountry: string;
    returnToCountryExplanation: string;
    appliedForPassport: string;
    appliedForPassportExplanation: string;
    receiveBenefits: string;
    receiveBenefitsExplanation: string;
    reacquiredNationality: string;
    reacquiredNationalityExplanation: string;
    acquiredNewNationality: string;
    acquiredNewNationalityExplanation: string;
    grantedAsyleeForOtherCountry: string;
    grantedAsyleeForOtherCountryExplanation: string;
    fileForRefugeeBeforeLeavingUs: string;
    fileForRefugeeBeforeLeavingUsExplanation: string;
    currentlyOutsideUs: string;
    currentLocation: string;
    otherCountries: string;
  };
  totalTimeOutsideUs: string;
  proposedTravel: {
    departureDate: string;
    purpose: string;
    countriesIntendedToVisit: string;
    numberOfTrips: string;
    expectedLength: string;
  };
  initPipReparole: {
    intendedRecipientOutsideOfUS: string;
    qualification: string;
    lengthOfStayInUs: string;
    dateOfIntendedArrival: string;
    locationToNotify: {
      cityOrTown: string;
      country: string;
    };
  };
  eadForNewOrReparole: string;
  applicantContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    signature: string;
    dateOfSignature: string;
  };
  interpreterContactAndCertification: {
    hasInterpreter: string;
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    language: string;
    signature: string;
    dateOfSignature: string;
  };
  preparerContactAndCertification: {
    hasPreparer: string;
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    signature: string;
    dateOfSignature: string;
  };
}

export interface FormPageComponentProps {
  section: string;
  subSection?: string;
  formikProps: FormikProps<FormValues>;
}

export interface SubSection {
  id: string;
  menuTitle: string;
  page: React.ComponentType<FormPageComponentProps>;
  validation?: Yup.ObjectSchema<Partial<FormValues>>;
  hidden: boolean | ((values: FormValues) => boolean);
}

export interface Section {
  id: string;
  menuTitle: string;
  description: string;
  page?: React.ComponentType<FormPageComponentProps>;
  subSections?: SubSection[];
  initialValues?: Partial<FormValues>;
  cleanup?: (setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => void;
  hidden?: (values: FormValues) => boolean;
}

export interface FormConfig {
  formId: string;
  formTitle: string;
  initialValues: FormValues;
  sections: Section[];
}

export const DEFAULT_FORM_VALUES: FormValues = {
  formStatus: 'draft',
  lastUpdated: new Date().toISOString(),
  applicationType: '',
  tpsBeneficiary: {
    tpsReceiptNumber: '',
  },
  inUsAdvanceParoleDocument: {
    program: '',
    receiptNumber: '',
  },
  outsideUsInitParoleDocument: {
    program: '',
    forPerson: '',
    receiptNumber: '',
    govExecBranch: '',
    repEmailAddress: '',
    otherProgram: '',
  },
  selfOrOtherInUsInitParole: {
    program: '',
    forPersonStatus: '',
    receiptNumber: '',
    otherProgram: '',
  },
  selfOrOtherRenewParole: {
    program: '',
    forPerson: '',
    forPersonStatus: '',
    receiptNumber: '',
    otherProgram: '',
  },
  i94AdmittedDate: '',
  refugeeOrLprRefugee: '',
  biographicInfo: {
    ethnicity: '',
    race: [''],
    height: {
      feet: '',
      inches: '',
    },
    weight: '',
    eyeColor: '',
    hairColor: '',
  },
  processingInfo: {
    previousProceedings: '',
    previousReentryPermit: {
      issued: '',
      dateIssued: '',
      disposition: '',
    },
    previousAdvanceParole: {
      issued: '',
      dateIssued: '',
      disposition: '',
    },
    requestReplacement: '',
    reasonForReplacement: '',
    infoCorrection: [''],
    infoCorrectionExplanation: '',
    replacementReceiptNumber: '',
    documentSendTo: {
      toUsAddressOrEmbassy: '',
      embassyCityOrTown: '',
      embassyCountry: '',
    },
    noticeSendTo: {
      selectDestination: '',
      address: {
        inCareOfName: '',
        street: '',
        unitType: '',
        unitNumber: '',
        cityOrTown: '',
        state: '',
        zipCode: '',
        province: '',
        postalCode: '',
        country: '',
      },
      daytimePhone: '',
      emailAddress: '',
    },
  },
  refugeeTravelDocumentInfo: {
    originCountry: '',
    planToTravelBack: '',
    planToTravelBackExplanation: '',
    returnToCountry: '',
    returnToCountryExplanation: '',
    appliedForPassport: '',
    appliedForPassportExplanation: '',
    receiveBenefits: '',
    receiveBenefitsExplanation: '',
    reacquiredNationality: '',
    reacquiredNationalityExplanation: '',
    acquiredNewNationality: '',
    acquiredNewNationalityExplanation: '',
    grantedAsyleeForOtherCountry: '',
    grantedAsyleeForOtherCountryExplanation: '',
    fileForRefugeeBeforeLeavingUs: '',
    fileForRefugeeBeforeLeavingUsExplanation: '',
    currentlyOutsideUs: '',
    currentLocation: '',
    otherCountries: '',
  },
  totalTimeOutsideUs: '',
  proposedTravel: {
    departureDate: '',
    purpose: '',
    countriesIntendedToVisit: '',
    numberOfTrips: '',
    expectedLength: '',
  },
  initPipReparole: {
    intendedRecipientOutsideOfUS: '',
    qualification: '',
    lengthOfStayInUs: '',
    dateOfIntendedArrival: '',
    locationToNotify: {
      cityOrTown: '',
      country: '',
    },
  },
  eadForNewOrReparole: '',
  applicantContactAndCertification: {
    daytimePhone: '',
    mobilePhone: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
  interpreterContactAndCertification: {
    hasInterpreter: '',
    fullName: {
      familyName: '',
      givenName: '',
    },
    daytimePhone: '',
    mobilePhone: '',
    businessName: '',
    email: '',
    language: '',
    signature: '',
    dateOfSignature: '',
  },
  preparerContactAndCertification: {
    hasPreparer: '',
    fullName: {
      familyName: '',
      givenName: '',
    },
    daytimePhone: '',
    mobilePhone: '',
    businessName: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
};

export interface BaseFormPageProps {
  onMenuItemClick?: (section: string) => void;
}

export interface MenuItem {
  id: string;
  label: string;
}

export interface ChatMessage {
  text: string;
  isBot: boolean;
  timestamp: Date;
}

export interface LocationState {
  formType?: string;
}

export type FormCategory = 'Immigration' | 'Citizenship' | 'Travel' | 'Family' | 'Employment';
export type FormStatus = 'ready' | 'inProgress' | 'notAvailable';

export interface FileAFormConfig {
  route: string;
  formValue?: string;
  description: string;
}

export type FormOption = 'I-131v2';

export type ApplicationFormProps = FormPageComponentProps;

export interface FieldRenderProps {
  field: {
    name: string;
    value: string;
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLDivElement>
    ) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLDivElement>) => void;
  };
}

export interface FormDataError {
  message: string;
  code?: string;
  details?: unknown;
}

export interface FormDataOptions extends Omit<UseQueryOptions, 'queryKey' | 'queryFn'> {
  cacheTime?: number;
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
}

export interface SaveFormDataMutationOptions {
  onError?: (error: FormDataError) => void;
}

export interface NavigationCheck {
  hasNext: boolean;
  nextSection?: string;
}

export interface PreviousCheck {
  hasPrevious: boolean;
  previousSection?: string;
  shouldUpdateApplicationType?: boolean;
  newApplicationType?: string;
}

export interface NavigationHelpers {
  isLastSection: boolean;
  isLastSubSection: boolean;
}

export interface FormContentProps {
  selectedSection: string;
  selectedSubSection: string;
  checkNextSubSection: (currentSectionId: string, currentSubSectionId: string, values: FormValues) => NavigationCheck;
  checkPreviousSubSection: (currentSectionId: string, currentSubSectionId: string, values: FormValues) => PreviousCheck;
  createVisibleSubSectionsMap: (values: FormValues) => Record<string, SubSection[]>;
  handleNext: (formikProps: FormikProps<FormValues>) => Promise<void>;
  handleBack: (formikProps: FormikProps<FormValues>) => Promise<void>;
  formConfig: FormConfig;
  getCurrentSubSection: () => SubSection | null;
  handleSubSectionClick: (sectionId: string, subSectionId: string) => void;
  handleSectionClick: (sectionId: string) => void;
  expandedSections: Record<string, boolean>;
  unlockedSections: Record<string, boolean>;
}

export interface FormFactoryProps {
  onMenuItemClick?: (sectionId: string) => void;
}

// Base type for all schemas
export type BaseSchema = {
  formStatus?: string;
  lastUpdated?: string;
  applicationType?: string;
};

// Application Type Schema Type
export type ApplicationTypeSchema = Partial<FormValues> & {
  applicationType: string;
  refugeeOrLprRefugee: string;
};

// TPS Beneficiary Schema Type
export type TPSBeneficiarySchema = Partial<FormValues> & {
  applicationType: string;
  tpsBeneficiary: {
    tpsReceiptNumber: string;
  };
};

// Advance Parole Schema Type
export type AdvanceParoleSchema = Partial<FormValues> & {
  applicationType: string;
  inUsAdvanceParoleDocument: {
    program: string;
    receiptNumber: string;
  };
};

// Outside Initial Parole Schema Type
export type OutsideInitialParoleSchema = Partial<FormValues> & {
  applicationType: string;
  outsideUsInitParoleDocument: {
    program: string;
    forPerson: string;
    receiptNumber: string;
    govExecBranch: string;
    repEmailAddress: string;
    otherProgram: string;
  };
};

// Self/Other In US Initial Parole Schema Type
export type SelfOtherInUsInitParoleSchema = Partial<FormValues> & {
  applicationType: string;
  selfOrOtherInUsInitParole: {
    program: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
};

// Self/Other Renew Parole Schema Type
export type SelfOtherRenewParoleSchema = Partial<FormValues> & {
  applicationType: string;
  selfOrOtherRenewParole: {
    program: string;
    forPerson: string;
    forPersonStatus: string;
    otherProgram: string;
  };
};

// I-94 Info Schema Type
export type I94InfoSchema = Partial<FormValues> & {
  applicationType: string;
  i94AdmittedDate: string;
};

// Update the createPartialSchema helper
export const createPartialSchema = <T extends Partial<FormValues>>(shape: Yup.ObjectShape): Yup.ObjectSchema<T> => {
  return Yup.object().shape(shape) as Yup.ObjectSchema<T>;
};
