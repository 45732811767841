import React from 'react';
import { Field, FormikErrors } from 'formik';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Box, Typography } from '@mui/material';
import { FieldRenderProps, FormPageComponentProps, FormValues } from 'types/form.types';

const SelfOtherRenewParoleInfo: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { values, touched, errors, setFieldValue, setFieldTouched, validateForm } = formikProps;
  const renewParoleOptions = [
    { id: 'A', label: 'Family Reunification Parole Process' },
    { id: 'B', label: 'Certain Afghans Paroled Into the United States After July 31, 2021' },
    { id: 'C', label: 'Certain Ukrainians Paroled Into the United States After February 24, 2022' },
    { id: 'D', label: 'Filipino World War II Veterans Parole (FWVP) Program' },
    { id: 'E', label: 'Immigrant Military Members and Veterans Initiative (IMMVI)' },
    { id: 'F', label: 'Central American Minors (CAM) Program' },
    { id: 'G', label: 'Family Reunification Task Force (FRTF) Process' },
    { id: 'H', label: 'Military Parole in Place (Military PIP)' },
    { id: 'I', label: 'Other: (List specific program or process)' },
  ];

  const immviPersonOptions = [
    { id: '1', label: 'A current or former service member' },
    {
      id: '2',
      label:
        'A current spouse, child, or unmarried son or daughter (or their child under 21 years of age) of a current or former service member',
    },
    { id: '3', label: 'Current legal guardian or surrogate of a current or former service member' },
  ];

  const militaryPipOptions = [
    { id: '1', label: 'A current or former service member' },
    { id: '2', label: 'A spouse, parent, son, or daughter of a current or former service member' },
  ];

  const handleProgramChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newValue = e.target.value;
    await setFieldTouched('selfOrOtherRenewParole.program', true, false);
    clearRenewParoleFields(newValue);
    await setFieldValue('selfOrOtherRenewParole.program', newValue, true);
    validateForm();
  };

  const handlePersonChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { name, value } = e.target;
    await setFieldTouched(name, true, false);
    await setFieldValue(name, value, true);
    validateForm();
  };

  const handleOtherProgramChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { name, value } = e.target;
    await setFieldTouched(name, true, false);
    await setFieldValue(name, value, true);
    validateForm();
  };

  const clearRenewParoleFields = (program: string): void => {
    if (program !== 'E') {
      setFieldValue('selfOrOtherRenewParole.forPerson', '');
    }
    if (program !== 'H') {
      setFieldValue('selfOrOtherRenewParole.forPersonStatus', '');
    }
    if (program !== 'I') {
      setFieldValue('selfOrOtherRenewParole.otherProgram', '');
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        Renew Parole Information
      </Typography>
      <FormControl
        component="fieldset"
        error={touched.selfOrOtherRenewParole?.program && Boolean(errors.selfOrOtherRenewParole?.program)}
        sx={{ mt: 2 }}
      >
        <FormLabel component="legend">Under one of the following programs or processes:</FormLabel>
        <Field name="selfOrOtherRenewParole.program">
          {({ field }: FieldRenderProps): JSX.Element => (
            <RadioGroup
              {...field}
              onChange={handleProgramChange}
              onBlur={(): Promise<FormikErrors<FormValues>> => validateForm()}
            >
              {renewParoleOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        </Field>

        {values.selfOrOtherRenewParole?.program === 'E' && (
          <FormControl
            component="fieldset"
            error={touched.selfOrOtherRenewParole?.forPerson && Boolean(errors.selfOrOtherRenewParole?.forPerson)}
            sx={{ mt: 2, ml: 4 }}
          >
            <FormLabel component="legend">Select one of the following:</FormLabel>
            <Field name="selfOrOtherRenewParole.forPerson">
              {({ field }: FieldRenderProps): JSX.Element => (
                <RadioGroup
                  {...field}
                  onChange={handlePersonChange}
                  onBlur={(): Promise<FormikErrors<FormValues>> => validateForm()}
                >
                  {immviPersonOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        )}

        {values.selfOrOtherRenewParole?.program === 'H' && (
          <FormControl
            component="fieldset"
            error={
              touched.selfOrOtherRenewParole?.forPersonStatus && Boolean(errors.selfOrOtherRenewParole?.forPersonStatus)
            }
            sx={{ mt: 2, ml: 4 }}
          >
            <FormLabel component="legend">Select one of the following:</FormLabel>
            <Field name="selfOrOtherRenewParole.forPersonStatus">
              {({ field }: FieldRenderProps): JSX.Element => (
                <RadioGroup
                  {...field}
                  onChange={handlePersonChange}
                  onBlur={(): Promise<FormikErrors<FormValues>> => validateForm()}
                >
                  {militaryPipOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        )}

        {values.selfOrOtherRenewParole?.program === 'I' && (
          <Field name="selfOrOtherRenewParole.otherProgram">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label="List specific program or process"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                error={
                  touched.selfOrOtherRenewParole?.otherProgram && Boolean(errors.selfOrOtherRenewParole?.otherProgram)
                }
                helperText={touched.selfOrOtherRenewParole?.otherProgram && errors.selfOrOtherRenewParole?.otherProgram}
                onChange={handleOtherProgramChange}
                onBlur={(): Promise<FormikErrors<FormValues>> => validateForm()}
              />
            )}
          </Field>
        )}
      </FormControl>
    </Box>
  );
};

export default SelfOtherRenewParoleInfo;
