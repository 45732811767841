import { useEffect, useMemo, useRef, useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormData, useSaveFormData } from 'services/formDataService';
import {
  DEFAULT_FORM_VALUES,
  FormDataError,
  FormFactoryProps,
  FormValues,
  NavigationCheck,
  PreviousCheck,
  SaveFormDataMutationOptions,
  Section,
  SubSection,
} from 'types/form.types';
import { useQueryClient } from '@tanstack/react-query';
import { useFormInitialization } from './useFormInitialization';
import { useBeforeUnloadSave } from './useBeforeUnloadSave';
import { useFormCleanup } from './useFormCleanup';
import { FormContent } from './FormContent';
import { clearFormState } from 'utils/formStateUtils';
import { FormState } from 'types/hooks.types';

type FormStateUpdater = (prevState: FormState) => FormState;

export const FORM_QUERY_KEY = 'form-data';

const FormFactory: React.FC<FormFactoryProps> = () => {
  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as { state: { formType: string } };
  const queryClient = useQueryClient();
  const mountedRef = useRef(true);
  const previousValuesRef = useRef<FormValues | null>(null);
  const initializeStartedRef = useRef(false);

  // Must be at the top level of your component
  const formId = useMemo(() => state?.formType || '', [state?.formType]);

  // Must be effect at the top, before other effects
  useEffect(() => {
    mountedRef.current = true;
    initializeStartedRef.current = false;

    const cleanup = (): void => {
      mountedRef.current = false;

      // Only log if we actually started initialization
      if (initializeStartedRef.current) {
        console.log('FormFactory unmounting:', {
          previousValues: previousValuesRef.current,
          currentLocation: location,
          formConfig,
          selectedSection,
          selectedSubSection,
        });
      }
    };

    return cleanup;
  }, []);

  // Update values ref whenever they change
  useEffect(() => {
    if (formikRef.current?.values) {
      previousValuesRef.current = formikRef.current.values;
    }
  }, [formikRef.current?.values]);

  // Make form state updates safe
  const safeSetState = useCallback((updater: FormStateUpdater): FormState | undefined => {
    if (mountedRef.current) {
      // Check if we're still mounted
      return updater({} as FormState);
    }
    return undefined;
  }, []);

  const {
    formConfig,
    selectedSection,
    setSelectedSection,
    selectedSubSection,
    setSelectedSubSection,
    expandedSections,
    setExpandedSections,
    unlockedSections,
    setUnlockedSections,
  } = useFormInitialization(state?.formType, safeSetState);

  // This effect must be after useFormInitialization
  useEffect(() => {
    if (formConfig) {
      initializeStartedRef.current = true;
    }
  }, [formConfig]);

  // Local error boundary
  useEffect(() => {
    const handleError = (event: ErrorEvent): void => {
      console.error('FormFactory caught error:', {
        error: event.error,
        message: event.message,
        // Log relevant state
        state: {
          location,
          formConfig,
          selectedSection,
          selectedSubSection,
          previousValues: previousValuesRef.current,
        },
      });
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, [location, formConfig, selectedSection, selectedSubSection]);

  useEffect(() => {
    const currentPath = location.pathname;

    return () => {
      if (currentPath.includes('/form') && mountedRef.current) {
        // Clear the form data only when leaving the form entirely
        if (formConfig?.formId) {
          queryClient.removeQueries({
            queryKey: ['form-data', formConfig.formId],
            exact: true,
          });

          // Also clear any related data
          queryClient.removeQueries({
            predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
          });
        }
      }
    };
  }, [location.pathname, formConfig?.formId, queryClient]);

  useFormCleanup(formConfig);

  const checkNextSubSection = useCallback(
    (currentSectionId: string, currentSubSectionId: string, values: FormValues): NavigationCheck => {
      if (!formConfig) return { hasNext: false };

      // Find current section and its index
      const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === currentSectionId);
      const currentSection = formConfig.sections[currentSectionIndex];

      if (!currentSection?.subSections) return { hasNext: false };

      // Find current subsection index
      const currentSubSection = currentSubSectionId.replace(`${currentSectionId}-`, '');
      const visibleSubSections = currentSection.subSections.filter(
        (ss) => !(typeof ss.hidden === 'function' ? ss.hidden(values) : ss.hidden)
      );
      const currentIndex = visibleSubSections.findIndex((ss) => ss.id === currentSubSection);

      // Check if there are more visible subsections in current section
      if (currentIndex < visibleSubSections.length - 1) {
        return { hasNext: true };
      }

      // Check next sections for visible subsections
      for (let i = currentSectionIndex + 1; i < formConfig.sections.length; i++) {
        const nextSection = formConfig.sections[i];
        // Check if section is hidden
        const isSectionHidden =
          typeof nextSection.hidden === 'function' ? nextSection.hidden(values) : !!nextSection.hidden;

        if (!isSectionHidden) {
          const hasVisibleSubSections = nextSection.subSections?.some(
            (ss) => !(typeof ss.hidden === 'function' ? ss.hidden(values) : ss.hidden)
          );

          if (hasVisibleSubSections) {
            return {
              hasNext: true,
              nextSection: nextSection.id,
            };
          }
        }
      }

      // If we get here, there are no more visible sections/subsections
      return { hasNext: false };
    },
    [formConfig]
  );

  const checkPreviousSubSection = useCallback(
    (currentSectionId: string, currentSubSectionId: string, values: FormValues): PreviousCheck => {
      if (!formConfig) return { hasPrevious: false };

      // Special case for i94-info
      if (currentSubSectionId.includes('i94-info') && values.applicationType === '11') {
        return {
          hasPrevious: true,
          shouldUpdateApplicationType: true,
          newApplicationType: '10',
        };
      }

      const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === currentSectionId);
      const currentSection = formConfig.sections[currentSectionIndex];

      if (!currentSection?.subSections) return { hasPrevious: false };

      const currentIndex = currentSection.subSections.findIndex(
        (ss) => `${currentSectionId}-${ss.id}` === currentSubSectionId
      );

      // Check previous subsections in current section
      for (let i = currentIndex - 1; i >= 0; i--) {
        const subsection = currentSection.subSections[i];
        const isHidden = typeof subsection.hidden === 'function' ? subsection.hidden(values) : subsection.hidden;

        if (!isHidden) {
          return { hasPrevious: true };
        }
      }

      // Check previous sections
      for (let i = currentSectionIndex - 1; i >= 0; i--) {
        const prevSection = formConfig.sections[i];
        // Check if section is hidden
        const isSectionHidden =
          typeof prevSection.hidden === 'function' ? prevSection.hidden(values) : !!prevSection.hidden;

        if (!isSectionHidden && prevSection.subSections?.some((ss) => !ss.hidden)) {
          return { hasPrevious: true };
        }
      }

      return { hasPrevious: false };
    },
    [formConfig]
  );

  const getVisibleSubSections = useCallback((section: Section, values: FormValues): SubSection[] => {
    return (
      section.subSections?.filter((subSection) => {
        const isHidden = typeof subSection.hidden === 'function' ? subSection.hidden(values) : subSection.hidden;
        return !isHidden;
      }) || []
    );
  }, []);

  const createVisibleSubSectionsMap = useCallback(
    (values: FormValues): Record<string, SubSection[]> => {
      if (!formConfig) return {};
      return formConfig.sections
        .filter((section) => {
          const isHidden = typeof section.hidden === 'function' ? section.hidden(values) : !!section.hidden;
          return !isHidden;
        })
        .reduce(
          (acc, section) => {
            acc[section.id] = getVisibleSubSections(section, values);
            return acc;
          },
          {} as Record<string, SubSection[]>
        );
    },
    [formConfig, getVisibleSubSections]
  );

  const saveFormMutation = useSaveFormData({
    onError: (error: FormDataError) => {
      console.error('Error saving form data:', error);
    },
  } as SaveFormDataMutationOptions);

  const handleAutoSave = useCallback(
    async (values: FormValues): Promise<void> => {
      if (formConfig) {
        await saveFormMutation.mutateAsync({
          id: formConfig.formId,
          values,
          formType: state?.formType || '',
          lastUpdated: new Date().toISOString(),
        });
      }
    },
    [formConfig, saveFormMutation, state?.formType]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateCurrentSection = async (
    values: FormValues,
    currentSection: Section | undefined,
    currentSubSection: SubSection | null
  ): Promise<boolean> => {
    if (!currentSection || !currentSubSection?.validation) return true;

    try {
      // Create validation object based on subsection
      const validationFields: Record<string, unknown> = {};

      // Common fields that might be needed across sections
      if (currentSection.id === 'application-type') {
        validationFields.applicationType = values.applicationType;
      }

      switch (currentSubSection.id) {
        // Application Type Section
        case 'select-type':
          validationFields.formStatus = values.formStatus;
          validationFields.lastUpdated = values.lastUpdated;
          validationFields.refugeeOrLprRefugee = values.refugeeOrLprRefugee;
          break;

        case 'renew-parole':
          if (values.applicationType === '10') {
            validationFields.selfOrOtherRenewParole = values.selfOrOtherRenewParole;

            // Check if required fields are present based on program selection
            const program = values.selfOrOtherRenewParole?.program;
            if (!program) {
              throw new Error('Please select a program');
            }

            if (program === 'E' && !values.selfOrOtherRenewParole?.forPerson) {
              throw new Error('Please select a status');
            }
            if (program === 'H' && !values.selfOrOtherRenewParole?.forPersonStatus) {
              throw new Error('Please select a status');
            }
            if (program === 'I' && !values.selfOrOtherRenewParole?.otherProgram) {
              throw new Error('Please specify the program or process');
            }
          }
          break;

        case 'i94-info':
          if (['10', '11'].includes(values.applicationType)) {
            validationFields.i94AdmittedDate = values.i94AdmittedDate;
          }
          break;

        case 'tps-beneficiary':
          if (values.applicationType === '4') {
            validationFields.tpsBeneficiary = values.tpsBeneficiary;
          }
          break;

        case 'advance-parole':
          if (values.applicationType === '5') {
            validationFields.inUsAdvanceParoleDocument = values.inUsAdvanceParoleDocument;
          }
          break;

        case 'outside-us-parole':
          if (values.applicationType === '6') {
            validationFields.outsideUsInitParoleDocument = values.outsideUsInitParoleDocument;
          }
          break;

        case 'self-other-parole':
          if (values.applicationType === '8') {
            validationFields.selfOrOtherInUsInitParole = values.selfOrOtherInUsInitParole;
          }
          break;

        // Employment Authorization Section
        case 'ead-request-page':
          validationFields.eadForNewOrReparole = values.eadForNewOrReparole;
          break;

        // case 'applicant-contact-information-page':
        //   validationFields.applicantContactAndCertification.daytimePhone =
        //     values.applicantContactAndCertification.daytimePhone;
        //   break;

        // case 'applicant-certification-signature-page':
        //   validationFields.eadForNewOrReparole = values.eadForNewOrReparole;
        //   break;

        default:
          // For any other subsections, include all fields from the form values
          // that are relevant to the current section
          Object.entries(values).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
              validationFields[key] = value;
            }
          });
          break;
      }

      // LEAVE IN FOR Debug logging
      // console.log('Validating fields:', {
      //   sectionId: currentSection.id,
      //   subsectionId: currentSubSection.id,
      //   fields: validationFields,
      // });

      // Validate using the subsection schema
      await currentSubSection.validation.validate(validationFields, {
        abortEarly: false,
        strict: false,
      });

      return true;
    } catch (err) {
      console.log('Section validation error:', err);
      return false;
    }
  };

  const getCurrentSubSection = useCallback((): SubSection | null => {
    if (!formConfig || !selectedSection || !selectedSubSection) return null;

    const section = formConfig.sections.find((s) => s.id === selectedSection);
    if (!section?.subSections) return null;

    const subSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    return section.subSections.find((ss) => ss.id === subSectionId) || null;
  }, [formConfig, selectedSection, selectedSubSection]);

  const handleFormSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      if (formConfig) {
        try {
          await saveFormMutation.mutateAsync({
            id: formConfig.formId,
            values,
            formType: state?.formType || '',
            lastUpdated: new Date().toISOString(),
          });

          // Clear form state after successful submission
          clearFormState(queryClient, formConfig.formId);

          console.log('Form submitted successfully', values);

          // Navigate to root path
          navigate('/', { replace: true });
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      }
    },
    [formConfig, saveFormMutation, state?.formType, queryClient, navigate]
  );

  const handleBack = useCallback(
  async (formikProps: FormikProps<FormValues>): Promise<void> => {
    const { values } = formikProps;
    await handleAutoSave(values);

    const currentSectionIndex = formConfig?.sections.findIndex((s) => s.id === selectedSection) ?? -1;
    const visibleSubSections = createVisibleSubSectionsMap(values)[selectedSection] || [];
    const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    const currentIndex = visibleSubSections.findIndex((ss) => ss.id === currentSubSectionId);

    if (currentIndex > 0) {
      // Move to previous subsection in current section
      const newExpandedSections: Record<string, boolean> = {
        ...expandedSections,
        [selectedSection]: true,
      };

      setExpandedSections(newExpandedSections);
      setSelectedSection(selectedSection);
      setSelectedSubSection(`${selectedSection}-${visibleSubSections[currentIndex - 1].id}`);
    } else if (currentSectionIndex > 0) {
      // Move to last subsection of previous section
      const previousSection = formConfig?.sections[currentSectionIndex - 1];
      if (previousSection) {
        const previousSectionSubSections = createVisibleSubSectionsMap(values)[previousSection.id] || [];
        if (previousSectionSubSections.length > 0) {
          const lastSubSection = previousSectionSubSections[previousSectionSubSections.length - 1];
          
          // Expand previous section and collapse current section
          const newExpandedSections: Record<string, boolean> = {
            ...expandedSections,
            [selectedSection]: false,
            [previousSection.id]: true,
          };

          setExpandedSections(newExpandedSections);
          setSelectedSection(previousSection.id);
          setSelectedSubSection(`${previousSection.id}-${lastSubSection.id}`);
        }
      }
    }
  },
  [
    selectedSection,
    selectedSubSection,
    handleAutoSave,
    createVisibleSubSectionsMap,
    formConfig,
    setSelectedSection,
    setSelectedSubSection,
    setExpandedSections,
    expandedSections,
  ]
);

  const handleNext = useCallback(
  async (formikProps: FormikProps<FormValues>): Promise<void> => {
    const { values } = formikProps;

    try {
      // Get current section and subsection
      const currentSection = formConfig?.sections.find((s) => s.id === selectedSection);
      const currentSubSection = getCurrentSubSection();

      if (!currentSection || !formConfig) {
        console.log('No current section or form config found');
        return;
      }

      // Validate current section
      const isValid = await validateCurrentSection(values, currentSection, currentSubSection);
      if (!isValid) {
        console.log('Current section validation failed');
        return;
      }

      // Save current state
      await handleAutoSave(values);

      const visibleSubSectionsMap = createVisibleSubSectionsMap(values);
      const currentSectionSubSections = visibleSubSectionsMap[selectedSection] || [];
      const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
      const currentIndex = currentSectionSubSections.findIndex((ss) => ss.id === currentSubSectionId);

      // Check if we should move to the next section
      if (currentIndex === currentSectionSubSections.length - 1) {
        // Find next section with visible subsections
        const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === selectedSection);

        // Look for next section with visible subsections
        for (let i = currentSectionIndex + 1; i < formConfig.sections.length; i++) {
          const nextSection = formConfig.sections[i];
          if (!nextSection) continue;

          const nextSectionSubSections = visibleSubSectionsMap[nextSection.id] || [];

          if (nextSectionSubSections.length > 0) {
            // FOR DEBUGGING PURPOSES, LEAVE IN.
            // console.log('Moving to next section:', {
            //   fromSection: selectedSection,
            //   toSection: nextSection.id,
            //   toSubSection: nextSectionSubSections[0].id,
            // });

            // Create new expanded sections state and unlock next section
            const newExpandedSections: Record<string, boolean> = {
              ...expandedSections,
              [selectedSection]: false,
              [nextSection.id]: true,
            };

            const newUnlockedSections: Record<string, boolean> = {
              ...unlockedSections,
              [nextSection.id]: true,
            };
            
            setExpandedSections(newExpandedSections);
            setUnlockedSections(newUnlockedSections);
            setSelectedSection(nextSection.id);
            setSelectedSubSection(`${nextSection.id}-${nextSectionSubSections[0].id}`);
            return;
          }
        }

        // console.log('No next section found with visible subsections');
        return;
      }

      // Move to next subsection in current section
      const nextSubSection = currentSectionSubSections[currentIndex + 1];
      if (nextSubSection) {
        // LEAVE IN FOR DEBUGGING PURPOSES.
        // console.log('Moving to next subsection:', {
        //   section: selectedSection,
        //   fromSubSection: currentSubSectionId,
        //   toSubSection: nextSubSection.id,
        // });

        // Create new expanded sections state
        const newExpandedSections: Record<string, boolean> = {
          ...expandedSections,
          [selectedSection]: true,
        };

        setExpandedSections(newExpandedSections);
        setSelectedSection(selectedSection);
        setSelectedSubSection(`${selectedSection}-${nextSubSection.id}`);
      }
    } catch (error) {
      console.error('Error in handleNext:', error);
    }
  },
  [
    selectedSection,
    selectedSubSection,
    handleAutoSave,
    formConfig,
    getCurrentSubSection,
    validateCurrentSection,
    createVisibleSubSectionsMap,
    setSelectedSection,
    setSelectedSubSection,
    setExpandedSections,
    setUnlockedSections,
    expandedSections,
    unlockedSections,
  ]
);

  useBeforeUnloadSave(formikRef, formConfig, state?.formType, saveFormMutation);

  const { data: storedFormData, isLoading } = useFormData(formId, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!formId,
    staleTime: 0,
    initialData: () => {
      // Always start with default values
      return {
        id: formId,
        values: DEFAULT_FORM_VALUES,
        formType: state?.formType || '',
        lastUpdated: new Date().toISOString(),
      };
    },
  });

  // Local cleanup effect
  useEffect(() => {
    const cleanup = (): void => {
      clearFormState(queryClient, formId);
    };
 
    // Clear on mount to ensure fresh start
    if (formId) cleanup();
  }, [formId, queryClient]);

  // Extracted initial values calculation
  const initialValues = useMemo((): FormValues => {
    if (!formConfig) return DEFAULT_FORM_VALUES;
    return storedFormData && storedFormData.values
      ? { ...DEFAULT_FORM_VALUES, ...formConfig.initialValues, ...storedFormData.values }
      : { ...DEFAULT_FORM_VALUES, ...formConfig.initialValues };
  }, [formConfig, storedFormData]);

  useEffect(() => {
    const handleRouteChange = (): void => {
      if (formConfig?.formId && mountedRef.current) {
        // Clear the specific form data
        queryClient.setQueryData(['form-data', formConfig.formId], null);

        // Remove all form-related queries
        queryClient.removeQueries({
          predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
        });
      }
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [formConfig?.formId, queryClient]);

  const handleSectionClick = useCallback(
    (sectionId: string): void => {
      setExpandedSections({
        ...expandedSections,
        [sectionId]: !expandedSections[sectionId],
      });
    },
    [expandedSections, setExpandedSections]
  );

  const handleSubSectionClick = useCallback(
    (sectionId: string, subSectionId: string): void => {
      const fullSubSectionId = `${sectionId}-${subSectionId}`;

      // Remove the resetForm call when changing sections
      setSelectedSection(sectionId);
      setSelectedSubSection(fullSubSectionId);
    },
    [setSelectedSection, setSelectedSubSection]
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!formConfig) {
    return <Typography>No form configuration found</Typography>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      innerRef={formikRef}
      validateOnMount={false}
      initialTouched={{}}
      validationSchema={getCurrentSubSection()?.validation}
      validateOnChange={true}
      validateOnBlur={true}
      validate={(): Record<string, string> => {
        const currentSubSection = getCurrentSubSection();
        if (!currentSubSection) return {};
        return {}; // Let validateCurrentSection handle the validation
      }}
    >
      <FormContent
        selectedSection={selectedSection}
        selectedSubSection={selectedSubSection}
        checkNextSubSection={checkNextSubSection}
        checkPreviousSubSection={checkPreviousSubSection}
        createVisibleSubSectionsMap={createVisibleSubSectionsMap}
        handleNext={handleNext}
        handleBack={handleBack}
        formConfig={formConfig}
        getCurrentSubSection={getCurrentSubSection}
        handleSubSectionClick={handleSubSectionClick}
        handleSectionClick={handleSectionClick}
        expandedSections={expandedSections}
        unlockedSections={unlockedSections} 
      />
    </Formik>
  );
};

export default FormFactory;
