import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { FormConfig } from 'types/form.types';
import { clearFormState } from 'utils/formStateUtils';

export const useFormCleanup = (formConfig: FormConfig | undefined): void => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const cleanupStartedRef = useRef(false);

  useEffect(() => {
    return () => {
      if (!cleanupStartedRef.current && formConfig?.formId) {
        cleanupStartedRef.current = true;
        clearFormState(queryClient, formConfig.formId);
      }
    };
  }, [formConfig?.formId, queryClient]);

  // Location change cleanup
  useEffect(() => {
    const currentPath = location.pathname;

    if (!currentPath.includes('/form') && formConfig?.formId) {
      clearFormState(queryClient, formConfig.formId);
    }
  }, [location.pathname, formConfig?.formId, queryClient]);
};
