import * as Yup from 'yup';
import { addressSchema } from '../../../utils/I131.schema';
import { emailValidation, validatePhoneNumber } from '../../../utils/pureFunctions';
import { createPartialSchema, FormValues } from '../../../types/form.types';

type documentSendToSchemaType = Partial<FormValues> & {
  processingInfo: {
    documentSendTo: {
      toUsAddressOrEmbassy: string;
      embassyCityOrTown: string;
      embassyCountry: string;
    };
    noticeSendTo: {
      selectDestination: string;
      address: {
        inCareOfName: string;
        street: string;
        unitType: string;
        unitNumber: string;
        cityOrTown: string;
        state: string;
        zipCode: string;
        province: string;
        postalCode: string;
        country: string;
      };
      daytimePhone: string;
      emailAddress: string;
    };
  };
};

const documentSendToSchema = createPartialSchema<documentSendToSchemaType>({
  processingInfo: Yup.object({
    documentSendTo: Yup.object({
      toUsAddressOrEmbassy: Yup.string().default('').oneOf(['', 'usAddress', 'usEmbassy']),
      embassyCityOrTown: Yup.string().default(''),
      embassyCountry: Yup.string().default(''),
    }),
    noticeSendTo: Yup.object({
      selectDestination: Yup.string().default('').oneOf(['', 'toP2Q3', 'toP4Q9a']),
      address: addressSchema,
      daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
      emailAddress: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    }),
  }),
});
export type DocumentSendToSchema = Yup.InferType<typeof documentSendToSchema>;

export default documentSendToSchema;
