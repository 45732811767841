import * as Yup from 'yup';
import { booleanSchema } from '../../../utils/I131.schema';
import { createPartialSchema, FormValues } from '../../../types/form.types';

type HasInterpreterSchemaType = Partial<FormValues> & {
  interpreterContactAndCertification: {
    hasInterpreter: string;
  };
};

const HasInterpreterSchema = createPartialSchema<HasInterpreterSchemaType>({
  interpreterContactAndCertification: Yup.object({
    hasInterpreter: booleanSchema,
  }),
});

export default HasInterpreterSchema;
