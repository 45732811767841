import { createPartialSchema, FormValues } from '../../../types/form.types';
import { checkDate } from '../../../utils/pureFunctions';
import * as Yup from 'yup';

type InterpreterCertificationSchemaType = Partial<FormValues> & {
  interpreterContactAndCertification: {
    language: string;
    signature: string;
    dateOfSignature: string;
  };
};

const InterpreterCertificationSchema = createPartialSchema<InterpreterCertificationSchemaType>({
  interpreterContactAndCertification: Yup.object({
    language: Yup.string().default(''),
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value) => checkDate(value)),
  }),
});

export default InterpreterCertificationSchema;
