import { createPartialSchema, FormValues } from '../../../types/form.types';
import { booleanSchema } from '../../../utils/I131.schema';
import { checkDate } from '../../../utils/pureFunctions';
import * as Yup from 'yup';

type IntendedRecipientSchemaType = Partial<FormValues> & {
  initPipReparole: {
    intendedRecipientOutsideOfUS: string;
    dateOfIntendedArrival: string;
    locationToNotify: {
      cityOrTown: string;
      country: string;
    };
  };
};

const IntendedRecipientSchema = createPartialSchema<IntendedRecipientSchemaType>({
  initPipReparole: Yup.object({
    intendedRecipientOutsideOfUS: booleanSchema,
    dateOfIntendedArrival: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, true, false)),
    locationToNotify: Yup.object({
      cityOrTown: Yup.string().default(''),
      country: Yup.string().default(''),
    }),
  }),
});

export default IntendedRecipientSchema;
