import { createPartialSchema, FormValues } from '../../../types/form.types';
import * as Yup from 'yup';

type PurposeOfTripSchemaType = Partial<FormValues> & {
  proposedTravel: {
    purpose: string;
  };
};

const PurposeOfTripSchema = createPartialSchema<PurposeOfTripSchemaType>({
  proposedTravel: Yup.object({
    purpose: Yup.string().default(''),
  }),
});

export default PurposeOfTripSchema;
