import { createPartialSchema, FormValues } from '../../../types/form.types';
import { checkDate } from '../../../utils/pureFunctions';
import * as Yup from 'yup';

type ApplicantCertificationSchemaType = Partial<FormValues> & {
  applicantContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
  };
};

const ApplicantCertificationSchema = createPartialSchema<ApplicantCertificationSchemaType>({
  applicantContactAndCertification: Yup.object({
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value) => checkDate(value)),
  }),
});

export default ApplicantCertificationSchema;
