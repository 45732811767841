import * as React from 'react';
import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import BooleanRadio from '../../SharedPages/BooleanRadio/BooleanRadio';
import ExplanationBox from '../../SharedPages/ExplanationBox/ExplanationBox';
import { BooleanSchema } from '../../../utils/I131.schema';

// Generic type for form values that can handle any nested structure
interface GenericFormValues {
  [key: string]: {
    [key: string]: BooleanSchema | string;
  };
}

interface BooleanQuestionProps {
  question: string;
  fieldName: string; // Format: "section.field" (e.g., "biographicInfo.planToTravelBack")
}

const BooleanRadioWithExplanationBox = ({ question, fieldName }: BooleanQuestionProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<GenericFormValues>();

  // Split the fieldName into section and field
  const [section, field] = fieldName.split('.');

  // Safely access nested values
  const currentValue = values[section]?.[field] as BooleanSchema;
  const showExplanation = currentValue === 'Yes';

  // Construct explanation field name
  const explanationFieldName = `${section}.${field}Explanation`;

  // Track previous value for cleanup
  const previousValue = React.useRef(currentValue);

  React.useEffect(() => {
    // Only clear explanation when switching from Yes to No or empty
    if (previousValue.current === 'Yes' && currentValue !== 'Yes') {
      setFieldValue(explanationFieldName, '');
    }
    previousValue.current = currentValue;
  }, [currentValue, setFieldValue, explanationFieldName]);

  return (
    <>
      <BooleanRadio
        question={question}
        fieldName={fieldName}
      />
      {showExplanation && <ExplanationBox name={explanationFieldName} />}
    </>
  );
};

export default BooleanRadioWithExplanationBox;
