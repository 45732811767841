/* eslint-disable no-useless-escape */
import { createPartialSchema, FormValues } from '../../../types/form.types';
import { emailValidation } from '../../../utils/pureFunctions';
import * as Yup from 'yup';

type InterpreterContactInformationSchemaType = Partial<FormValues> & {
  interpreterContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
  };
};

const InterpreterContactInformationSchema = createPartialSchema<InterpreterContactInformationSchemaType>({
  interpreterContactAndCertification: Yup.object({
    daytimePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || value.replace(/[- \(\)]/g, '').match(/^\d{10}$/) !== null
      ),
    mobilePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || value.replace(/[- \(\)]/g, '').match(/^\d{10}$/) !== null
      ),
    email: Yup.string()
      .default('')
      .test('emailValidation', 'Invalid email', (value) => !value || emailValidation(value)),
  }),
});

export default InterpreterContactInformationSchema;
