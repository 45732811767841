/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const QualifyForParolePage: React.FC = () => {
  return (
    <Field name="initPipReparole.qualification">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
        >
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: 400,
              mb: 2,
              color: 'text.primary',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
          >
            Explain how you qualify for parole, parole in place, or re-parole. Include copies of any supporting
            documents or evidence you wish considered.
          </FormLabel>

          <TextField
            {...field}
            multiline
            rows={4}
            inputProps={{
              maxLength: 1000,
            }}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />

          <Typography
            variant="body2"
            sx={{
              mt: 1,
              color: 'text.secondary',
              fontSize: '0.875rem',
            }}
          >
            Maximum character limit is 1000.
          </Typography>

          {meta.touched && meta.error && (
            <Typography
              color="error"
              variant="caption"
              sx={{ mt: 1 }}
            >
              {meta.error}
            </Typography>
          )}

          {field.value && (
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                color: 'text.secondary',
              }}
            >
              {field.value.length}/1000 characters
            </Typography>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default QualifyForParolePage;
