import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type PlanToTravelBackSchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    planToTravelBack: BooleanSchema;
    planToTravelBackExplanation: string;
  };
};

const planToTravelBackSchema = createPartialSchema<PlanToTravelBackSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    planToTravelBack: booleanSchema,
    planToTravelBackExplanation: Yup.string().default(''),
  }),
});

export default planToTravelBackSchema;
