import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { Typography, Divider, List, ListItem, ListItemButton, ListItemText, Collapse, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext, Form } from 'formik';
import { LayoutContainer, Column, ContentSection } from 'styles/styledComponents';
import { FormValues, FormContentProps } from 'types/form.types';
import { useFormNavigation } from './useFormNavigation';
import AiChatAssistant from '../AiChatAssistant';
import useFormSectionValidation from './useFormSectionValidation';
import { useScrollToTop } from 'hooks';

export const FormContent: React.FC<FormContentProps> = ({
  selectedSection,
  selectedSubSection,
  checkNextSubSection,
  checkPreviousSubSection,
  createVisibleSubSectionsMap,
  handleNext,
  handleBack,
  formConfig,
  getCurrentSubSection,
  handleSubSectionClick,
  handleSectionClick,
  expandedSections,
  unlockedSections,
}) => {
  const formik = useFormikContext<FormValues>();

  const scrollToTop = useScrollToTop();

  const navigationState = useFormNavigation(
    selectedSection,
    selectedSubSection,
    formik,
    checkNextSubSection,
    checkPreviousSubSection,
    createVisibleSubSectionsMap,
    handleNext,
    handleBack,
    formConfig
  );

  const {
    nextCheck,
    previousCheck,
    visibleSubSectionsMap,
    handleNextClick,
    handleBackClick,
    isLastSection,
    isLastSubSection,
  } = navigationState;

  const currentSubSection = getCurrentSubSection();
  const { isValid, isSubmitting, isValidating } = useFormSectionValidation(getCurrentSubSection);

  const isSectionHidden = (section: (typeof formConfig.sections)[0]): boolean => {
    if (typeof section.hidden === 'function') {
      return section.hidden(formik.values);
    }
    return !!section.hidden;
  };

  return (
    <Form onClick={(e: React.MouseEvent): void => e.stopPropagation()}>
      {/* Left Column - Form Navigation */}
      <LayoutContainer sx={{ marginTop: 4 }}>
        <Box sx={{ width: '25%' }}>
          <Column>
            <ContentSection>
              <Typography variant="h5" gutterBottom>
                {formConfig.formTitle}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <List>
                {formConfig.sections.map((section) => {
                  const visibleSubSections = visibleSubSectionsMap[section.id];
                  const isUnlocked = unlockedSections[section.id];

                  // Skip rendering this section if it's hidden
                  if (isSectionHidden(section)) {
                    return null;
                  }

                  return (
                    <div key={section.id}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={(): void => {
                            if (isUnlocked) {
                              handleSectionClick(section.id);
                            }
                          }}
                          selected={selectedSection === section.id}
                          disabled={!isUnlocked}
                          sx={{
                            opacity: isUnlocked ? 1 : 0.5,
                            cursor: isUnlocked ? 'pointer' : 'not-allowed',
                          }}
                        >
                          {expandedSections[section.id] ? <ExpandMore /> : <ChevronRight />}
                          <ListItemText
                            primary={section.menuTitle}
                            secondary={section.description}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={expandedSections[section.id] && isUnlocked}>
                        <List sx={{ pl: 4 }}>
                          {visibleSubSections?.map((subSection) => (
                            <ListItem
                              key={`${section.id}-${subSection.id}`}
                              disablePadding
                            >
                              <ListItemButton
                                onClick={(): void => {
                                  if (isUnlocked) {
                                    handleSubSectionClick(section.id, subSection.id);
                                  }
                                }}
                                selected={selectedSubSection === `${section.id}-${subSection.id}`}
                                disabled={!isUnlocked}
                                sx={{
                                  opacity: isUnlocked ? 1 : 0.5,
                                  cursor: isUnlocked ? 'pointer' : 'not-allowed',
                                }}
                              >
                                <ListItemText primary={subSection.menuTitle} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  );
                })}
              </List>
            </ContentSection>
          </Column>
        </Box>

        {/* Right Column - Form Content */}
        <Box sx={{ width: '75%' }}>
          <Column>
            <ContentSection>
              {currentSubSection && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    {currentSubSection.menuTitle}
                  </Typography>
                  <Divider sx={{ mb: 3 }} />
                  <currentSubSection.page
                    section={selectedSection}
                    subSection={selectedSubSection}
                    formikProps={formik}
                  />
                </>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                {previousCheck.hasPrevious && (
                  <Button
                    variant="outlined"
                    onClick={handleBackClick}
                    type="button"
                    disabled={isSubmitting}
                  >
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
                    e.preventDefault();
                    // LEAVE IN FOR DEBUGGING PURPOSES. THIS IS NOT FINAL CC.
                    // console.log('Button clicked:', {
                    //   isLastSection,
                    //   isLastSubSection,
                    //   nextCheck,
                    //   currentSection: selectedSection,
                    //   currentSubSection: selectedSubSection,
                    // });

                    if (isLastSection && isLastSubSection) {
                      await formik.submitForm();
                    } else {
                      await handleNextClick();
                      scrollToTop();
                    }
                  }}
                  type="button"
                  sx={{ ml: 'auto' }}
                  disabled={!isValid || isSubmitting || isValidating}
                >
                  {isLastSection && isLastSubSection ? 'Submit' : 'Save and Continue'}
                </Button>
              </Box>
            </ContentSection>
          </Column>
        </Box>
      </LayoutContainer>
      <AiChatAssistant formType={formConfig.formTitle} />
    </Form>
  );
};
