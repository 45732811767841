/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const IntendedRecipientPage: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setFieldValue('initPipReparole.intendedRecipientOutsideOfUS', newValue);

    // If changing to "No", reset dependent fields
    if (newValue === 'No') {
      setFieldValue('initPipReparole.dateOfIntendedArrival', '');
      setFieldValue('initPipReparole.locationToNotify.cityOrTown', '');
      setFieldValue('initPipReparole.locationToNotify.country', '');
    }
  };
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Field name="initPipReparole.intendedRecipientOutsideOfUS">
          {({ field, meta }: { field: any; meta: any }): ReactElement => (
            <FormControl
              component="fieldset"
              error={meta.touched && !!meta.error}
              sx={{ width: '100%' }}
            >
              <FormLabel
                component="legend"
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 400,
                  mb: 2,
                  color: 'text.primary',
                  '&.Mui-focused': {
                    color: 'text.primary',
                  },
                }}
              >
                Is the person intended to receive the parole document outside the United States?
              </FormLabel>

              <RadioGroup
                {...field}
                onChange={handleRadioChange}
                sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderColor: field.value === 'No' ? 'primary.main' : 'grey.300',
                    borderRadius: 1,
                    p: 2,
                    flex: 1,
                    bgcolor: field.value === 'No' ? 'primary.50' : 'background.paper',
                  }}
                >
                  <FormControlLabel
                    value={'No'}
                    control={
                      <Radio
                        icon={<CancelIcon sx={{ color: 'primary.main' }} />}
                        checkedIcon={<CancelIcon sx={{ color: 'primary.main' }} />}
                      />
                    }
                    label="No"
                    sx={{
                      width: '100%',
                      margin: 0,
                      '.MuiFormControlLabel-label': {
                        flex: 1,
                        textAlign: 'center',
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    border: 1,
                    borderColor: field.value === 'Yes' ? 'primary.main' : 'grey.300',
                    borderRadius: 1,
                    p: 2,
                    flex: 1,
                    bgcolor: field.value === 'Yes' ? 'primary.50' : 'background.paper',
                  }}
                >
                  <FormControlLabel
                    value={'Yes'}
                    control={
                      <Radio
                        icon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                        checkedIcon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                      />
                    }
                    label="Yes"
                    sx={{
                      width: '100%',
                      margin: 0,
                      '.MuiFormControlLabel-label': {
                        flex: 1,
                        textAlign: 'center',
                      },
                    }}
                  />
                </Box>
              </RadioGroup>

              {meta.touched && meta.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1 }}
                >
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          )}
        </Field>
      </Box>

      {values.initPipReparole.intendedRecipientOutsideOfUS === 'Yes' && (
        <>
          <Box sx={{ mb: 4 }}>
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 1,
                display: 'block',
                color: 'text.primary',
              }}
            >
              Date of Intended Arrival to the United States
            </FormLabel>

            <Field name="initPipReparole.dateOfIntendedArrival">
              {({ field, meta }: { field: any; meta: any }): ReactElement => (
                <FormControl
                  fullWidth
                  error={meta.touched && !!meta.error}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                    }}
                  >
                    Date (mm/dd/yyyy)
                  </Typography>

                  <TextField
                    {...field}
                    type="date"
                    inputProps={{
                      min: new Date().toISOString().split('T')[0],
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#BDBDBD',
                        },
                      },
                    }}
                  />

                  {meta.touched && meta.error && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mt: 1 }}
                    >
                      {meta.error}
                    </Typography>
                  )}
                </FormControl>
              )}
            </Field>
          </Box>

          <Box>
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 2,
                display: 'block',
                color: 'text.primary',
              }}
            >
              Location (City or Town and Country of the U.S. Embassy, U.S. Consulate, or the USCIS international field
              office that you want us to notify.
            </FormLabel>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Field name="initPipReparole.locationToNotify.cityOrTown">
                {({ field, meta }: { field: any; meta: any }): ReactElement => (
                  <FormControl
                    fullWidth
                    error={meta.touched && !!meta.error}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                      }}
                    >
                      City or Town
                    </Typography>

                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&:hover fieldset': {
                            borderColor: '#BDBDBD',
                          },
                        },
                      }}
                    />

                    {meta.touched && meta.error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mt: 1 }}
                      >
                        {meta.error}
                      </Typography>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="initPipReparole.locationToNotify.country">
                {({ field, meta }: { field: any; meta: any }): ReactElement => (
                  <FormControl
                    fullWidth
                    error={meta.touched && !!meta.error}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                      }}
                    >
                      Country
                    </Typography>

                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&:hover fieldset': {
                            borderColor: '#BDBDBD',
                          },
                        },
                      }}
                    />

                    {meta.touched && meta.error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mt: 1 }}
                      >
                        {meta.error}
                      </Typography>
                    )}
                  </FormControl>
                )}
              </Field>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default IntendedRecipientPage;
