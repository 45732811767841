/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import BooleanRadioPage from '../../SharedPages/BooleanRadio/BooleanRadio';
import { Field } from 'formik';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const PreviousAdvancedParolePage: React.FC = () => {
  return (
    <>
      <BooleanRadioPage
        question="Have you EVER before been issued an Advance Parole Document?"
        fieldName="processingInfo.previousAdvanceParole.issued"
      />

      <Field name="processingInfo.previousAdvanceParole.dateIssued">
        {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
          >
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 2,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              Provide the following information for the last document issued to you:
            </FormLabel>

            <Typography
              variant="body2"
              sx={{
                mb: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Date Issued (mm/dd/yyyy)
            </Typography>
            <TextField
              {...field}
              type="text"
              inputProps={{
                pattern: '\\d{2}/\\d{2}\\/\\d{4}',
                'aria-label': 'Issued date',
              }}
              value={field.value || ''}
              onBlur={(e): void => {
                const value = e.target.value;
                if (value === '' || (/^\d{2}\/\d{2}\/\d{4}$/.test(value) && !isNaN(Date.parse(value)))) {
                  form.setFieldValue(field.name, value);
                }
              }}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E0E0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#BDBDBD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />

            {meta.touched && meta.error && (
              <Typography
                color="error"
                variant="caption"
                sx={{ mt: 1 }}
              >
                {meta.error}
              </Typography>
            )}
          </FormControl>
        )}
      </Field>

      <Field name="processingInfo.previousAdvanceParole.disposition">
        {({ field, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl fullWidth>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Disposition (attached, lost, stolen, damaged/destroyed, still in my possession, etc.)
            </Typography>
            <TextField
              {...field}
              type="text"
              inputProps={{
                'aria-label': 'Disposition',
              }}
              value={field.value || ''}
              onChange={(e): void => {
                const value = e.target.value;
                // allow adding string
                form.setFieldValue(field.name, value);
              }}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E0E0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#BDBDBD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default PreviousAdvancedParolePage;
