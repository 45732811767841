// import I131OverviewPage from 'pages/I131OverviewPage/I131OverviewPage';
import I131ApplicationType from '../pages/I131ApplicationType/I131ApplicationType';
import {
  advanceParoleSchema,
  applicationTypeSelectSchema,
  i94InfoSchema,
  outsideInitialParoleSchema,
  selfOtherInUsInitParoleSchema,
  selfOtherRenewParoleSchema,
  tpsBeneficiarySchema,
} from '../pages/I131ApplicationType/I131ApplicationType.schema';
import { FormConfig, DEFAULT_FORM_VALUES, FormValues } from 'types/form.types';
import {
  InUsAdvanceParoleInfo,
  OutsideInitialParoleInfo,
  SelfOtherInUsInitParoleInfo,
  SelfOtherRenewParoleInfo,
  I94Info,
  TPSBeneficiaryInfo,
} from '../containers/FormSections';
import EmploymentAuthorizationPage from '../pages/EmploymentAuthorization/EmploymentAuthorizationPage';
import EmploymentAuthorizationSchema from '../pages/EmploymentAuthorization/EmploymentAuthorizationPage.schema';
import ApplicantContactInformationPage from '../pages/ApplicantInformation/ApplicantContactInformation/ApplicantContactInformationPage';
import ApplicantContactInformationSchema from '../pages/ApplicantInformation/ApplicantContactInformation/ApplicantContactInformationPage.schema';
import ApplicantCertificationPage from '../pages/ApplicantInformation/ApplicantCertification/ApplicantCertificationPage';
import ApplicantCertificationSchema from '../pages/ApplicantInformation/ApplicantCertification/ApplicantCertificationPage.schema';
import InterpreterCertificationPage from '../pages/InterpreterInformation/InterpreterCertification/InterpreterCertificationPage';
import InterpreterCertificationSchema from '../pages/InterpreterInformation/InterpreterCertification/InterpreterCertificationPage.schema';
import InterpreterContactInformationPage from '../pages/InterpreterInformation/InterpreterContactInformation/InterpreterContactInformationPage';
import InterpreterContactInformationSchema from '../pages/InterpreterInformation/InterpreterContactInformation/InterpreterContactInformationPage.schema';
import InterpreterNamePage from '../pages/InterpreterInformation/InterpreterName/InterpreterNamePage';
import InterpreterNameSchema from '../pages/InterpreterInformation/InterpreterName/InterpreterNamePage.schema';
import HasInterpreterPage from '../pages/InterpreterInformation/HasInterpreter/HasInterpreterPage';
import HasInterpreterSchema from '../pages/InterpreterInformation/HasInterpreter/HasInterpreterPage.schema';
import HasPreparerPage from '../pages/PreparerInformation/HasPreparer/HasPreparerPage';
import HasPreparerSchema from '../pages/PreparerInformation/HasPreparer/HasPreparerPage.schema';
import PreparerNamePage from '../pages/PreparerInformation/PreparerName/PreparerNamePage';
import PreparerNameSchema from '../pages/PreparerInformation/PreparerName/PreparerNamePage.schema';
import PreparerContactInformationPage from '../pages/PreparerInformation/PreparerContactInformation/PreparerContactInformationPage';
import PreparerContactInformationSchema from '../pages/PreparerInformation/PreparerContactInformation/PreparerContactInformationPage.schema';
import PreparerCertificationPage from '../pages/PreparerInformation/PreparerCertification/PreparerCertificationPage';
import PreparerCertificationSchema from '../pages/PreparerInformation/PreparerCertification/PreparerCertificationPage.schema';
import QualifyForParolePage from '../pages/InitialPipReParole/QualifyForParolePage/QualifyForParolePage';
import QualifyForParoleSchema from '../pages/InitialPipReParole/QualifyForParolePage/QualifyForParolePage.schema';
import LengthOfStayInUSPage from '../pages/InitialPipReParole/LengthOfStayInUSPage/LengthOfStayInUSPage';
import LengthOfStayInUSSchema from '../pages/InitialPipReParole/LengthOfStayInUSPage/LengthOfStayInUSPage.schema';
import IntendedRecipientPage from '../pages/InitialPipReParole/IntendedRecipientPage/IntendedRecipientPage';
import IntendedRecipientSchema from '../pages/InitialPipReParole/IntendedRecipientPage/IntendedRecipientPage.schema';
import DateOfIntendedDeparturePage from '../pages/AdvancedParoleDocument/DateOfIntendedDeparturePage/DateOfIntendedDeparturePage';
import DateOfIntendedDepartureSchema from '../pages/AdvancedParoleDocument/DateOfIntendedDeparturePage/DateOfIntendedDeparturePage.schema';
import PurposeOfTripPage from '../pages/AdvancedParoleDocument/PurposeOfTripPage/PurposeOfTripPage';
import PurposeOfTripSchema from '../pages/AdvancedParoleDocument/PurposeOfTripPage/PurposeOfTrip.schema';
import CountriesIntendedToVisitPage from '../pages/AdvancedParoleDocument/CountriesIntendedToVisitPage/CountriesIntendedToVisitPage';
import CountriesIntendedToVisitSchema from '../pages/AdvancedParoleDocument/CountriesIntendedToVisitPage/CountriesIntendedToVisitPage.schema';
import LengthOfTripPage from '../pages/AdvancedParoleDocument/LengthOfTripPage/LengthOfTripPage';
import LengthOfTripSchema from '../pages/AdvancedParoleDocument/LengthOfTripPage/LengthOfTripPage.schema';
import ReentryPermitInformationPage from '../pages/ReentryPermitInformation/ReentryPermitInformationPage';
import ReentryPermitInformationSchema from '../pages/ReentryPermitInformation/ReentryPermitInformationPage.schema';
import PreviousProceedingsPage from '../pages/ProcessingInformation/PreviousProceeding/PreviousProceedings';
import previousProceedingsSchema from '../pages/ProcessingInformation/PreviousProceeding/PreviousProceedings.schema';
import PreviousReentryPage from '../pages/ProcessingInformation/PreviousReentry/PreviousReentry';
import previousReentrySchema from '../pages/ProcessingInformation/PreviousReentry/PreviousReentry.schema';
import PreviousAdvancedParolePage from '../pages/ProcessingInformation/PreviousAdvanceParole/PreviousAdvanceParole';
import previousAdvanceParoleSchema from '../pages/ProcessingInformation/PreviousAdvanceParole/PreviousAdvanceParole.schema';
import RequestReplacementPage from '../pages/ProcessingInformation/RequestReplacement/RequestReplacement';
import requestReplacementSchema from '../pages/ProcessingInformation/RequestReplacement/RequestReplacement.schema.';
import DocumentSendToPage from '../pages/ProcessingInformation/DocumentSendTo/DocumentSendTo';
import documentSendToSchema from '../pages/ProcessingInformation/DocumentSendTo/DocumentSendTo.schema';
import EthnicityPage from '../pages/BeneficiaryBiographicInformation/EthnicityPage/EthnicityPage';
import EthnicitySchema from '../pages/BeneficiaryBiographicInformation/EthnicityPage/EthnicityPage.schema';
import RacePage from '../pages/BeneficiaryBiographicInformation/RacePage/RacePage';
import RaceSchema from '../pages/BeneficiaryBiographicInformation/RacePage/RacePage.schema';
import HeightWeightPage from '../pages/BeneficiaryBiographicInformation/HeightWeightPage/HeightWeightPage';
import HeightWeightSchema from '../pages/BeneficiaryBiographicInformation/HeightWeightPage/HeightWeightPage.schema';
import HairColorPage from '../pages/BeneficiaryBiographicInformation/HairColorPage/HairColorPage';
import HairColorSchema from '../pages/BeneficiaryBiographicInformation/HairColorPage/HairColorPage.schema';
import EyeColorPage from '../pages/BeneficiaryBiographicInformation/EyeColorPage/EyeColorPage';
import EyeColorSchema from '../pages/BeneficiaryBiographicInformation/EyeColorPage/EyeColorPage.schema';
import CountryOriginSelect from '../pages/RefugeeTravelDocument/CountryOfOrigin/CountryOriginSelect';
import countryOriginSelectSchema from '../pages/RefugeeTravelDocument/CountryOfOrigin/countryOriginSelect.schema';
import PlanToTravelBack from '../pages/RefugeeTravelDocument/PlanToTravelBack/PlanToTravelBack';
import planToTravelBackSchema from '../pages/RefugeeTravelDocument/PlanToTravelBack/planToTravelBack.schema';
import ReturnToCountryQuestion from '../pages/RefugeeTravelDocument/ReturnToCountry/ReturnToCountry';
import returnToCountrySchema from '../pages/RefugeeTravelDocument/ReturnToCountry/returnToCountry.schema';
import AppliedForPassport from '../pages/RefugeeTravelDocument/AppliedForPassport/AppliedForPassport';
import appliedForPassportSchema from '../pages/RefugeeTravelDocument/AppliedForPassport/appliedForPassport.schema';
import ReceiveBenefitsQuestion from '../pages/RefugeeTravelDocument/ReceiveBenefits/ReceiveBenefits';
import receiveBenefitsSchema from '../pages/RefugeeTravelDocument/ReceiveBenefits/receiveBenefits.schema';
import ReacquiredNationality from '../pages/RefugeeTravelDocument/ReacquiredNationality/ReacquiredNationality';
import reacquiredNationalitySchema from '../pages/RefugeeTravelDocument/ReacquiredNationality/reacquiredNationality.schema';
import AcquiredNewNationality from '../pages/RefugeeTravelDocument/AcquiredNewNationality/AcquiredNewNationality';
import acquiredNewNationalitySchema from '../pages/RefugeeTravelDocument/AcquiredNewNationality/acquiredNewNationality.schema';
import GrantedAsyleeForOtherCountry from '../pages/RefugeeTravelDocument/GrantedAsyleeForOtherCountry/GrantedAsyleeForOtherCountry';
import grantedAsyleeForOtherCountrySchema from '../pages/RefugeeTravelDocument/GrantedAsyleeForOtherCountry/grantedAsyleeForOtherCountry.schema';

export const i131Config: FormConfig = {
  formId: 'i131v2',
  formTitle: 'Form I-131 for travel document',
  initialValues: {
    ...DEFAULT_FORM_VALUES,
    formStatus: 'draft',
    lastUpdated: new Date().toISOString(),
  },
  sections: [
    {
      id: 'application-type',
      menuTitle: 'Application Type',
      description: 'Select your application type',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'select-type',
          menuTitle: 'Select Application Type',
          page: I131ApplicationType,
          validation: applicationTypeSelectSchema,
          hidden: false,
        },
        {
          id: 'tps-beneficiary',
          menuTitle: 'TPS Beneficiary Information',
          page: TPSBeneficiaryInfo,
          validation: tpsBeneficiarySchema,
          hidden: (values: FormValues) => values.applicationType !== '4',
        },
        {
          id: 'advance-parole',
          menuTitle: 'Advance Parole Information',
          page: InUsAdvanceParoleInfo,
          validation: advanceParoleSchema,
          hidden: (values: FormValues) => values.applicationType !== '5',
        },
        {
          id: 'outside-us-parole',
          menuTitle: 'Outside US Initial Parole',
          page: OutsideInitialParoleInfo,
          validation: outsideInitialParoleSchema,
          hidden: (values: FormValues) => values.applicationType !== '6',
        },
        {
          id: 'self-other-parole',
          menuTitle: 'Self/Other Initial Parole',
          page: SelfOtherInUsInitParoleInfo,
          validation: selfOtherInUsInitParoleSchema,
          hidden: (values: FormValues) => values.applicationType !== '8',
        },
        {
          id: 'renew-parole',
          menuTitle: 'Renew Parole Information',
          page: SelfOtherRenewParoleInfo,
          validation: selfOtherRenewParoleSchema,
          hidden: (values: FormValues) => values.applicationType !== '10',
        },
        {
          id: 'i94-info',
          menuTitle: 'I-94 Information',
          page: I94Info,
          validation: i94InfoSchema,
          hidden: (values: FormValues) => !['10', '11'].includes(values.applicationType),
        },
      ],
    },
    {
      id: 'recipient-biographic-information-section',
      menuTitle: 'Recipient Biographic Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'ethnicity-page',
          menuTitle: 'Ethnicity',
          page: EthnicityPage,
          validation: EthnicitySchema,
          hidden: false,
        },
        {
          id: 'race-page',
          menuTitle: 'Race',
          page: RacePage,
          validation: RaceSchema,
          hidden: false,
        },
        {
          id: 'height-weight-page',
          menuTitle: 'Height and Weight',
          page: HeightWeightPage,
          validation: HeightWeightSchema,
          hidden: false,
        },
        {
          id: 'eye-color-page',
          menuTitle: 'Eye Color',
          page: EyeColorPage,
          validation: EyeColorSchema,
          hidden: false,
        },
        {
          id: 'hair-color-page',
          menuTitle: 'Hair Color',
          page: HairColorPage,
          validation: HairColorSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'processing-information-section',
      menuTitle: 'Processing Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'previous-proceedings-page',
          menuTitle: 'Previous Proceedings',
          page: PreviousProceedingsPage,
          validation: previousProceedingsSchema,
          hidden: false,
        },
        {
          id: 'previous-reentry-page',
          menuTitle: 'Previous Re-entry',
          page: PreviousReentryPage,
          validation: previousReentrySchema,
          hidden: false,
        },
        {
          id: 'previous-advanced-parole-page',
          menuTitle: 'Previous Advanced Parole',
          page: PreviousAdvancedParolePage,
          validation: previousAdvanceParoleSchema,
          hidden: false,
        },
        {
          id: 'replacement-document-page',
          menuTitle: 'Request Replacement Document',
          page: RequestReplacementPage,
          validation: requestReplacementSchema,
          hidden: false,
        },
        {
          id: 'send-documents-page',
          menuTitle: 'Where to Send Documents',
          page: DocumentSendToPage,
          validation: documentSendToSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'reentry-permit-information-section',
      menuTitle: 'Re-entry Permit Information',
      description: '',
      hidden: (values: FormValues) => !['1'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'reentry-permit-information-page',
          menuTitle: 'Re-entry Permit Information',
          page: ReentryPermitInformationPage,
          validation: ReentryPermitInformationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'refugee-travel-document-section',
      menuTitle: 'Refugee Travel Document',
      description: '',
      hidden: (values: FormValues) => !['2', '3'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'refugee-country-page',
          menuTitle: 'Refugee Country',
          page: CountryOriginSelect,
          validation: countryOriginSelectSchema,
          hidden: false,
        },
        {
          id: 'plan-to-travel-back-page',
          menuTitle: 'Plan to Travel Back to Country',
          page: PlanToTravelBack,
          validation: planToTravelBackSchema,
          hidden: false,
        },
        {
          id: 'returned-to-country-page',
          menuTitle: 'Returned to Country',
          page: ReturnToCountryQuestion,
          validation: returnToCountrySchema,
          hidden: false,
        },
        {
          id: 'applied-for-passport-page',
          menuTitle: 'Applied for Origin Country Passport',
          page: AppliedForPassport,
          validation: appliedForPassportSchema,
          hidden: false,
        },
        {
          id: 'recieved-benefits-page',
          menuTitle: 'Recieved Benefits from Origin Country',
          page: ReceiveBenefitsQuestion,
          validation: receiveBenefitsSchema,
          hidden: false,
        },
        {
          id: 'reacquired-nationality-page',
          menuTitle: 'Reacquired Nationality from Origin Country',
          page: ReacquiredNationality,
          validation: reacquiredNationalitySchema,
          hidden: false,
        },
        {
          id: 'new-nationality-page',
          menuTitle: 'Acquired New Nationality',
          page: AcquiredNewNationality,
          validation: acquiredNewNationalitySchema,
          hidden: false,
        },
        {
          id: 'granted-asylum-in-other-country-page',
          menuTitle: 'Granted Asylum in Another Country',
          page: GrantedAsyleeForOtherCountry,
          validation: grantedAsyleeForOtherCountrySchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'advanced-parole-document-section',
      menuTitle: 'Advanced Parole Document',
      description: '',
      hidden: (values: FormValues) => !['5'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'date-of-intended-departure-page',
          menuTitle: 'Date of Intended Departure',
          page: DateOfIntendedDeparturePage,
          validation: DateOfIntendedDepartureSchema,
          hidden: false,
        },
        {
          id: 'purpose-of-trip-page',
          menuTitle: 'Purpose of Trip',
          page: PurposeOfTripPage,
          validation: PurposeOfTripSchema,
          hidden: false,
        },
        {
          id: 'intended-countries-page',
          menuTitle: 'Countries Intended to Visit',
          page: CountriesIntendedToVisitPage,
          validation: CountriesIntendedToVisitSchema,
          hidden: false,
        },
        {
          id: 'length-of-trip-page',
          menuTitle: 'Length of Trip',
          page: LengthOfTripPage,
          validation: LengthOfTripSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'initial-pip-reparole-section',
      menuTitle: 'Initial Parole, Parole In-Place, Re-Parole Document',
      description: '',
      hidden: (values: FormValues) => !['6', '7', '8', '9', '10', '11'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'parole-qualification-page',
          menuTitle: 'Parole Qualifications',
          page: QualifyForParolePage,
          validation: QualifyForParoleSchema,
          hidden: false,
        },
        {
          id: 'length-of-stay-page',
          menuTitle: 'Expected Length of Stay',
          page: LengthOfStayInUSPage,
          validation: LengthOfStayInUSSchema,
          hidden: false,
        },
        {
          id: 'intended-recipient-page',
          menuTitle: 'Intended Recipient',
          page: IntendedRecipientPage,
          validation: IntendedRecipientSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'ead-request-section',
      menuTitle: 'Employment Authorization',
      description: '',
      hidden: (values: FormValues) => !['10', '11'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'ead-request-page',
          menuTitle: 'Employment Authorization',
          page: EmploymentAuthorizationPage,
          validation: EmploymentAuthorizationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'applicant-information-section',
      menuTitle: 'Applicant Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'applicant-contact-information-page',
          menuTitle: "Applicant's Contact Information",
          page: ApplicantContactInformationPage,
          validation: ApplicantContactInformationSchema,
          hidden: false,
        },
        {
          id: 'applicant-certification-signature-page',
          menuTitle: "Applicant's Certification and Signature",
          page: ApplicantCertificationPage,
          validation: ApplicantCertificationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'interpreter-information-section',
      menuTitle: 'Interpreter Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'has-interpreter-page',
          menuTitle: 'Used Interpreter',
          page: HasInterpreterPage,
          validation: HasInterpreterSchema,
          hidden: false,
        },
        {
          id: 'interpreter-name-page',
          menuTitle: "Interpreter's Full Name",
          page: InterpreterNamePage,
          validation: InterpreterNameSchema,
          hidden: (values: FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
        {
          id: 'interpreter-contact-information-page',
          menuTitle: "Interpreter's Contact Information",
          page: InterpreterContactInformationPage,
          validation: InterpreterContactInformationSchema,
          hidden: (values: FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
        {
          id: 'interpreter-certification-signature-page',
          menuTitle: "Interpreter's Certification and Signature",
          page: InterpreterCertificationPage,
          validation: InterpreterCertificationSchema,
          hidden: (values: FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
      ],
    },
    {
      id: 'preparer-information-section',
      menuTitle: 'Preparer Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'has-preparer-page',
          menuTitle: 'Used Preparer',
          page: HasPreparerPage,
          validation: HasPreparerSchema,
          hidden: false,
        },
        {
          id: 'preparer-name-page',
          menuTitle: "Preparer's Full Name",
          page: PreparerNamePage,
          validation: PreparerNameSchema,
          hidden: (values: FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
        {
          id: 'preparer-contact-information-page',
          menuTitle: "Preparer's Contact Information",
          page: PreparerContactInformationPage,
          validation: PreparerContactInformationSchema,
          hidden: (values: FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
        {
          id: 'preparer-certification-signature-page',
          menuTitle: "Preparer's Certification and Signature",
          page: PreparerCertificationPage,
          validation: PreparerCertificationSchema,
          hidden: (values: FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
      ],
    },
  ],
};
