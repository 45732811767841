import * as Yup from 'yup';
import { createPartialSchema, FormValues } from '../../../types/form.types';
import { booleanSchema } from '../../../utils/I131.schema';

type previousProceedingsSchemaType = Partial<FormValues> & {
  processingInfo: {
    previousProceedings: string;
  };
};

const previousProceedingsSchema = createPartialSchema<previousProceedingsSchemaType>({
  processingInfo: Yup.object({
    previousProceedings: booleanSchema,
  }),
});

export default previousProceedingsSchema;
