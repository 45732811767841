import * as React from 'react';
import CertificationSignaturePage from '../../../pages/SharedPages/CertificationSignaturePage/CertificationSignaturePage';

const InterpreterCertificationPage: React.FC = () => {
  return (
    <CertificationSignaturePage
      question="Interpreter's Certification and Signature"
      signatureLabel="Interpreter's Signature"
      signaturePath="interpreterContactAndCertification.signature"
      datePath="interpreterContactAndCertification.dateOfSignature"
      languagePath="interpreterContactAndCertification.language"
    />
  );
};

export default InterpreterCertificationPage;
