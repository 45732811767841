import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const PrivateRoute = ({ children }: { children?: ReactElement }): JSX.Element => {
  const auth = useAuth();
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (auth.isLoading) {
      setIsRefreshing(true);
    } else {
      const timer = setTimeout(() => setIsRefreshing(false), 100);
      return (): void => clearTimeout(timer);
    }
  }, [auth.isLoading]);

  if (auth.isLoading || isRefreshing) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return (
      <Navigate
        to="/login"
        replace
      />
    );
  }

  return children || <></>;
};

export default PrivateRoute;
