import { useEffect, useState } from 'react';
import { getFormConfig } from 'configs/formConfigs';
import { FormInitializationReturn, FormState } from 'types/hooks.types';

export const useFormInitialization = (
  formType: string | undefined,
  safeSetState: (updater: (prevState: FormState) => FormState) => FormState | undefined
): FormInitializationReturn => {
  const [formState, setFormState] = useState<FormState>({
    formConfig: undefined,
    selectedSection: '',
    selectedSubSection: '',
    expandedSections: {},
    unlockedSections: {},
  });

  useEffect(() => {
    if (!formType) return;

    setTimeout(() => {
      const config = getFormConfig(formType);
      if (!config) return;

      const initialSection = config.sections[0]?.id || '';
      const initialSubSection = config.sections[0]?.subSections?.[0]?.id || '';

      // Initialize with only first section expanded and unlocked
      const initialExpandedSections = config.sections.reduce(
        (acc, section) => ({ ...acc, [section.id]: section.id === initialSection }),
        {} as Record<string, boolean>
      );

      const initialUnlockedSections = config.sections.reduce(
        (acc, section) => ({ ...acc, [section.id]: section.id === initialSection }),
        {} as Record<string, boolean>
      );

      setFormState({
        formConfig: config,
        selectedSection: initialSection,
        selectedSubSection: `${initialSection}-${initialSubSection}`,
        expandedSections: initialExpandedSections,
        unlockedSections: initialUnlockedSections,
      });
    }, 0);
  }, [formType]);

  const setSelectedSection = (section: string): void => {
    setFormState((prevState: FormState) => ({
      ...prevState,
      selectedSection: section,
    }));
  };

  const setSelectedSubSection = (subSection: string): void => {
    setFormState((prev) => ({
      ...prev,
      selectedSubSection: subSection,
    }));
  };

  const setExpandedSections = (sections: Record<string, boolean>): void => {
    setFormState((prev) => ({
      ...prev,
      expandedSections: sections,
    }));
  };

  const setUnlockedSections = (sections: Record<string, boolean>): void => {
    setFormState((prev) => ({
      ...prev,
      unlockedSections: sections,
    }));
  };

  return {
    ...formState,
    setSelectedSection,
    setSelectedSubSection,
    setExpandedSections,
    setUnlockedSections,
  };
};