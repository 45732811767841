import { styled, Box, Container, Paper } from '@mui/material';

export const LayoutContainer = styled(Container)({
  display: 'flex',
  maxWidth: '100% !important',
  height: '100vh',
  padding: 0,
});

export const Column = styled(Paper)({
  height: '100vh',
  overflow: 'auto',
  borderRadius: 0,
});

export const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const ChatMessages = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  padding: '16px',
});

export const ChatInputContainer = styled(Box)({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '16px',
});

export const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isBot',
})<{ isBot: boolean }>(({ isBot, theme }) => ({
  maxWidth: '80%',
  padding: '8px 16px',
  backgroundColor: isBot ? theme.palette.grey[100] : theme.palette.primary.main,
  color: isBot ? theme.palette.text.primary : theme.palette.primary.contrastText,
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isBot ? 'flex-start' : 'flex-end',
}));

export const ContentSection = styled(Box)({
  padding: '16px',
});
