import * as React from 'react';
import ContactInformationPage from '../../SharedPages/ContactInformationPage/ContactInformationPage';

const PreparerContactInformationPage: React.FC = () => {
  return (
    <ContactInformationPage
      question="Preparer's Contact Information"
      daytimePhoneLabel="Preparer's Daytime Telephone Number"
      mobilePhoneLabel="Preparer's Mobile Telephone Number (if any)"
      emailLabel="Preparer's Email Address (if any)"
      daytimePhonePath="preparerContactAndCertification.daytimePhone"
      mobilePhonePath="preparerContactAndCertification.mobilePhone"
      emailPath="preparerContactAndCertification.email"
    />
  );
};

export default PreparerContactInformationPage;
