/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ContactInformationPage from '../../SharedPages/ContactInformationPage/ContactInformationPage';

const ApplicantContactInformationPage: React.FC = () => {
  return (
    <ContactInformationPage
      question="Applicant's Contact Information"
      daytimePhoneLabel="Applicant's Daytime Telephone Number"
      mobilePhoneLabel="Applicant's Mobile Telephone Number (if any)"
      emailLabel="Applicant's Email Address (if any)"
      daytimePhonePath="applicantContactAndCertification.daytimePhone"
      mobilePhonePath="applicantContactAndCertification.mobilePhone"
      emailPath="applicantContactAndCertification.email"
    />
  );
};

export default ApplicantContactInformationPage;
