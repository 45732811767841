import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { FieldRenderProps, FormPageComponentProps } from 'types/form.types';

const InUsAdvanceParoleInfo: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { values, touched, errors, setFieldValue } = formikProps;

  const advanceParoleOptions = [
    { id: 'A', label: 'A pending Form I-485, Application to Register Permanent Residence or Adjust Status' },
    { id: 'B', label: 'A pending Form I-589, Application for Asylum and for Withholding of Removal' },
    { id: 'C', label: 'A pending initial Form I-821, Application for Temporary Protected Status' },
    { id: 'D', label: 'Deferred Enforced Departure' },
    { id: 'E', label: 'Approved Form I-821D, Consideration of Deferred Action for Childhood Arrivals' },
    {
      id: 'F',
      label:
        'An approved Form I-914, Application for T Nonimmigrant Status, or Form I-914, Supplement A, Application for Family Member of T-1 Recipient',
    },
    {
      id: 'G',
      label:
        'An approved Form I-918, Petition for U Nonimmigrant Status, or Form I-918, Supplement A, Petition for Qualifying Family Member of U-1 Recipient',
    },
    { id: 'H', label: 'Being a current parolee under INA section 212(d)(5), under class of admission' },
    { id: 'I', label: 'An approved Form I-817, Application for Family Unity Benefits' },
    {
      id: 'J',
      label:
        'A pending Form I-687, Application for Status as a Temporary Resident Under Section 245A of the Immigration and Nationality Act',
    },
    { id: 'K', label: 'An approved V Nonimmigrant Status' },
    { id: 'L', label: 'CNMI long-term residence' },
    { id: 'M', label: 'Other (provide explanation)' },
  ];

  const clearAdvanceParoleFields = (program: string): void => {
    if (program !== 'D') {
      setFieldValue('inUsAdvanceParoleDocument.receiptNumber', '');
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        Advance Parole Document Information
      </Typography>
      <FormControl
        component="fieldset"
        error={touched.inUsAdvanceParoleDocument?.program && Boolean(errors.inUsAdvanceParoleDocument?.program)}
        sx={{ mt: 2, ml: 4 }}
      >
        <FormLabel
          component="legend"
          sx={{
            typography: 'body2',
            mb: 1,
            color: 'text.primary',
          }}
        >
          Based on:
        </FormLabel>
        <Field name="inUsAdvanceParoleDocument.program">
          {({ field }: FieldRenderProps): JSX.Element => (
            <RadioGroup
              {...field}
              onChange={(e): void => {
                const newValue = e.target.value;
                clearAdvanceParoleFields(newValue);
                setFieldValue('inUsAdvanceParoleDocument.program', newValue);
              }}
            >
              {advanceParoleOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        {touched.inUsAdvanceParoleDocument?.program && errors.inUsAdvanceParoleDocument?.program && (
          <FormHelperText>{errors.inUsAdvanceParoleDocument.program}</FormHelperText>
        )}
        {values.inUsAdvanceParoleDocument?.program && values.inUsAdvanceParoleDocument?.program !== 'D' && (
          <Field name="inUsAdvanceParoleDocument.receiptNumber">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label={
                  values.inUsAdvanceParoleDocument.program === 'M' ? 'Please provide an explanation' : 'Receipt Number'
                }
                variant="outlined"
                fullWidth
                margin="normal"
                error={
                  touched.inUsAdvanceParoleDocument?.receiptNumber &&
                  Boolean(errors.inUsAdvanceParoleDocument?.receiptNumber)
                }
                helperText={
                  touched.inUsAdvanceParoleDocument?.receiptNumber && errors.inUsAdvanceParoleDocument?.receiptNumber
                }
              />
            )}
          </Field>
        )}
      </FormControl>
    </Box>
  );
};

export default InUsAdvanceParoleInfo;
