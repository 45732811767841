/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, TextField, Grid, Box, Typography } from '@mui/material';

const HeightWeightPage: React.FC = () => {
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <FormLabel
          sx={{
            fontSize: '1.25rem',
            fontWeight: 400,
            mb: 2,
            display: 'block',
            color: 'text.primary',
            '&.Mui-focused': {
              color: 'text.primary',
            },
          }}
        >
          What is the height of the person who will receive the document?
        </FormLabel>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Field name="biographicInfo.height.feet">
              {({ field, meta }: { field: any; meta: any }): ReactElement => (
                <FormControl
                  fullWidth
                  error={meta.touched && !!meta.error}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                    }}
                  >
                    Feet
                  </Typography>
                  <TextField
                    {...field}
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 9,
                      step: 1,
                    }}
                    value={field.value || ''}
                    onChange={(e): void => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 9)) {
                        field.onChange(e);
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#BDBDBD',
                        },
                      },
                    }}
                  />
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Field name="biographicInfo.height.inches">
              {({ field, meta }: { field: any; meta: any }): ReactElement => (
                <FormControl
                  fullWidth
                  error={meta.touched && !!meta.error}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                    }}
                  >
                    Inches
                  </Typography>
                  <TextField
                    {...field}
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 11,
                      step: 1,
                    }}
                    value={field.value || ''}
                    onChange={(e): void => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 11)) {
                        field.onChange(e);
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#BDBDBD',
                        },
                      },
                    }}
                  />
                </FormControl>
              )}
            </Field>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <FormLabel
          sx={{
            fontSize: '1.25rem',
            fontWeight: 400,
            mb: 2,
            display: 'block',
            color: 'text.primary',
            '&.Mui-focused': {
              color: 'text.primary',
            },
          }}
        >
          What is the weight of the person who will receive the document?
        </FormLabel>

        <Field name="biographicInfo.weight">
          {({ field, meta }: { field: any; meta: any }): ReactElement => (
            <FormControl
              fullWidth
              error={meta.touched && !!meta.error}
            >
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  color: 'text.secondary',
                  fontSize: '0.875rem',
                }}
              >
                Pounds
              </Typography>
              <TextField
                {...field}
                type="number"
                inputProps={{
                  min: 0,
                  max: 999,
                  step: 1,
                }}
                value={field.value || ''}
                onChange={(e): void => {
                  const value = e.target.value;
                  if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
                    field.onChange(e);
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#E0E0E0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#BDBDBD',
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </Field>
      </Box>
    </>
  );
};

export default HeightWeightPage;
