import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { FieldRenderProps, FormPageComponentProps } from 'types/form.types';

const SelfOtherInUsInitParoleInfo: React.FC<FormPageComponentProps> = ({ formikProps }) => {
  const { values, touched, errors, setFieldValue } = formikProps;

  const paroleInPlaceOptions = [
    { id: 'A', label: 'Military Parole in Place (PIP)' },
    { id: 'B', label: 'Family Reunification Task Force (FRTF) Process; Task Force Registration Number:' },
    { id: 'C', label: 'Other: (List specific program or process)' },
  ];

  const paroleInPlaceMilitaryStatusOptions = [
    { id: '1', label: 'A current or former service member' },
    { id: '2', label: 'A spouse, parent, son, or daughter of a current or former service member' },
  ];

  const clearParoleInPlaceFields = (program: string): void => {
    if (program !== 'A') {
      setFieldValue('selfOrOtherInUsInitParole.forPersonStatus', '');
    }
    if (program !== 'B') {
      setFieldValue('selfOrOtherInUsInitParole.receiptNumber', '');
    }
    if (program !== 'C') {
      setFieldValue('selfOrOtherInUsInitParole.otherProgram', '');
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        Self/Other Initial Parole Information
      </Typography>
      <FormControl
        component="fieldset"
        error={touched.selfOrOtherInUsInitParole?.program && Boolean(errors.selfOrOtherInUsInitParole?.program)}
        sx={{ mt: 2 }}
      >
        <FormLabel component="legend">Under:</FormLabel>
        <Field name="selfOrOtherInUsInitParole.program">
          {({ field }: FieldRenderProps): JSX.Element => (
            <RadioGroup
              {...field}
              onChange={(e): void => {
                const newValue = e.target.value;
                clearParoleInPlaceFields(newValue);
                setFieldValue('selfOrOtherInUsInitParole.program', newValue);
              }}
            >
              {paroleInPlaceOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        {touched.selfOrOtherInUsInitParole?.program && errors.selfOrOtherInUsInitParole?.program && (
          <FormHelperText>{errors.selfOrOtherInUsInitParole.program}</FormHelperText>
        )}

        {values.selfOrOtherInUsInitParole?.program === 'A' && (
          <FormControl
            component="fieldset"
            error={
              touched.selfOrOtherInUsInitParole?.forPersonStatus &&
              Boolean(errors.selfOrOtherInUsInitParole?.forPersonStatus)
            }
            sx={{ mt: 2, ml: 4 }}
          >
            <FormLabel component="legend">Select one of the following:</FormLabel>
            <Field name="selfOrOtherInUsInitParole.forPersonStatus">
              {({ field }: FieldRenderProps): JSX.Element => (
                <RadioGroup {...field}>
                  {paroleInPlaceMilitaryStatusOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        )}

        {values.selfOrOtherInUsInitParole?.program === 'B' && (
          <Field name="selfOrOtherInUsInitParole.receiptNumber">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label="Task Force Registration Number"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                error={
                  touched.selfOrOtherInUsInitParole?.receiptNumber &&
                  Boolean(errors.selfOrOtherInUsInitParole?.receiptNumber)
                }
                helperText={
                  touched.selfOrOtherInUsInitParole?.receiptNumber && errors.selfOrOtherInUsInitParole?.receiptNumber
                }
              />
            )}
          </Field>
        )}

        {values.selfOrOtherInUsInitParole?.program === 'C' && (
          <Field name="selfOrOtherInUsInitParole.otherProgram">
            {({ field }: FieldRenderProps): JSX.Element => (
              <TextField
                {...field}
                label="List specific program or process"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                error={
                  touched.selfOrOtherInUsInitParole?.otherProgram &&
                  Boolean(errors.selfOrOtherInUsInitParole?.otherProgram)
                }
                helperText={
                  touched.selfOrOtherInUsInitParole?.otherProgram && errors.selfOrOtherInUsInitParole?.otherProgram
                }
              />
            )}
          </Field>
        )}
      </FormControl>
    </Box>
  );
};

export default SelfOtherInUsInitParoleInfo;
