import { createPartialSchema, FormValues } from '../../../types/form.types';
import { BooleanSchema, booleanSchema } from '../../../utils/I131.schema';
import * as Yup from 'yup';

type GrantedAsyleeForOtherCountrySchemaType = Partial<FormValues> & {
  refugeeTravelDocumentInfo: {
    grantedAsyleeForOtherCountry: BooleanSchema;
    grantedAsyleeForOtherCountryExplanation: string;
  };
};

const grantedAsyleeForOtherCountrySchema = createPartialSchema<GrantedAsyleeForOtherCountrySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    grantedAsyleeForOtherCountry: booleanSchema,
    grantedAsyleeForOtherCountryExplanation: Yup.string().default(''),
  }),
});

export default grantedAsyleeForOtherCountrySchema;
