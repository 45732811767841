import * as React from 'react';
import HelperNamePage from '../../../pages/SharedPages/HelperNamePage/HelperNamePage';

const PreparerNamePage: React.FC = () => {
  return (
    <HelperNamePage
      question="Preparer's Full Name"
      familyNamePath={'preparerContactAndCertification.fullName.familyName'}
      givenNamePath={'preparerContactAndCertification.fullName.givenName'}
      businessPath={'preparerContactAndCertification.businessName'}
      familyNameLabel="Preparer's Family Name (Last Name)"
      givenNameLabel="Preparer's Given Name (First Name)"
      businessLabel="Preparer's Business or Organization Name (if any)"
    />
  );
};

export default PreparerNamePage;
