/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { Field } from 'formik';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { COUNTRY } from '../../../constants/countryConstants';

const docSendToOptions = [
  { label: 'To the U.S. address you entered in the “Information About “You” section', value: 'usAddress' },
  {
    label:
      'To a U.S. Embassy, U.S. Consulate, USCIS international field office, or Department of Homeland Security (DHS) office overseas at:',
    value: 'usEmbassy',
  },
];

const noticeSendToOptions = [
  { label: 'To the U.S. address you entered in the “Information About “You” section', value: 'toP2Q3' },
  { label: 'To a different address (please enter here):', value: 'toP4Q9a' },
];

const DocumentSendToPage: React.FC = () => {
  const [saveDocSendTo, setSaveDocSendTo] = useState('');
  const [saveNoticeSendTo, setSaveNoticeSendTo] = useState('');

  return (
    <>
      <FormControl
        fullWidth
        sx={{ width: '100%' }}
      >
        <FormLabel
          sx={{
            fontSize: '1.4rem',
            fontWeight: 400,
            mb: 1,
            color: 'text.primary',
            '&.Mui-focused': {
              color: 'text.primary',
            },
          }}
        >
          Complete the following if you are requesting a Reentry Permit or Refugee Travel Document.
        </FormLabel>
      </FormControl>

      <Field name="processingInfo.documentSendTo.toUsAddressOrEmbassy">
        {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
            required
            sx={{ width: '100%' }}
          >
            {!saveDocSendTo && field.value && setSaveDocSendTo(field.value)}
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 1,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              Where do you want your Reentry Permit or Refugee Travel Document sent? (Select one)
            </FormLabel>

            <RadioGroup
              {...field}
              onChange={(e): void => {
                setSaveDocSendTo(e.target.value);
                form.setFieldValue('processingInfo.documentSendTo.toUsAddressOrEmbassy', e.target.value);
              }}
            >
              {docSendToOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={field.value === option.value} />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Field>

      {saveDocSendTo === 'usEmbassy' && (
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <Box sx={{ flex: 1 }}>
            <div className="input-field-container">
              <label
                htmlFor="embassy-city-or-town"
                className="input-label"
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
                >
                  City or Town
                </Typography>
              </label>
              <Field name="processingInfo.documentSendTo.embassyCityOrTown">
                {({ field, meta }: any): ReactElement => (
                  <TextField
                    {...field}
                    id="embassy-city-or-town"
                    fullWidth
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                      },
                    }}
                  />
                )}
              </Field>
            </div>
          </Box>
          <Box sx={{ flex: 1 }}>
            <div className="input-field-container">
              <label
                htmlFor="embassy-country"
                className="input-label"
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
                >
                  City or Town
                </Typography>
              </label>
              <Field name="processingInfo.documentSendTo.embassyCountry">
                {({ field, meta }: any): ReactElement => (
                  <TextField
                    {...field}
                    id="embassy-country"
                    fullWidth
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                      },
                    }}
                  />
                )}
              </Field>
            </div>
          </Box>
        </Box>
      )}

      <Field name="processingInfo.noticeSendTo.selectDestination">
        {({ field, meta, form }: { field: any; meta: any; form: any }): ReactElement => (
          <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
            required
            sx={{ width: '100%' }}
          >
            {!saveNoticeSendTo && field.value && setSaveNoticeSendTo(field.value)}
            <FormLabel
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                mb: 1,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              Where should the notification to pick up the travel document be sent?
            </FormLabel>

            <RadioGroup
              {...field}
              onChange={(e): void => {
                setSaveNoticeSendTo(e.target.value);
                form.setFieldValue('processingInfo.noticeSendTo.selectDestination', e.target.value);
              }}
            >
              {noticeSendToOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={field.value === option.value} />}
                  label={option.label}
                  sx={{
                    marginBottom: 1,
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Field>

      {saveNoticeSendTo === 'toP4Q9a' && (
        <>
          <Field name="processingInfo.noticeSendTo.address.inCareOfName">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  In care of name
                </Typography>
                <TextField
                  {...field}
                  label="In care of name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.country">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Country
                </Typography>
                <Select
                  {...field}
                  displayEmpty
                  sx={{
                    mt: 1,
                    backgroundColor: 'background.paper',
                    '& .MuiSelect-select': {
                      padding: '16px 16px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0E0E0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#BDBDBD',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    maxWidth: 'calc(100% - 32px)',
                    margin: '8px 0px 32px 32px',
                  }}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    <em>Select a country</em>
                  </MenuItem>
                  {COUNTRY.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        fontSize: '1rem',
                        '&.Mui-selected': {
                          backgroundColor: '#EBF3FF',
                          '&:hover': {
                            backgroundColor: '#E3EDF9',
                          },
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.street">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Street number and name
                </Typography>
                <TextField
                  {...field}
                  label="Street"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.unitNumber">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Suite, unit, or floor number
                </Typography>
                <TextField
                  {...field}
                  label="Unit number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.cityOrTown">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  City or town
                </Typography>
                <TextField
                  {...field}
                  label="City or town"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.province">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Province
                </Typography>
                <TextField
                  {...field}
                  label="Province"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.postalCode">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Postal code
                </Typography>
                <TextField
                  {...field}
                  label="Postal code"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.daytimePhone">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Daytime phone number
                </Typography>
                <TextField
                  {...field}
                  label="Daytime phone number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>

          <Field name="processingInfo.noticeSendTo.address.emailAddress">
            {({ field }: { field: any; meta: any; form: any }): ReactElement => (
              <FormControl fullWidth>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  Email address
                </Typography>
                <TextField
                  {...field}
                  label="Email address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ ml: 4, maxWidth: 'calc(100% - 32px)' }}
                />
              </FormControl>
            )}
          </Field>
        </>
      )}
    </>
  );
};

export default DocumentSendToPage;
