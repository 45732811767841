// FormFactoryLoader.tsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import FormFactory from './FormFactory';
import EnhancedFormDebugWrapper from 'auth/EnhancedFormDebugWrapper';
import FormErrorBoundary from 'auth/FormErrorBoundry';

const FormFactoryLoader: React.FC = (): JSX.Element => {
  const [isTransitioning, setIsTransitioning] = useState(true);
  const location = useLocation();
  const [key, setKey] = useState(location.key);

  useEffect(() => {
    if (location.key !== key) {
      setIsTransitioning(true);
      setKey(location.key);
    }

    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 50);

    return (): void => {
      clearTimeout(timer);
    };
  }, [location.key, key]);

  if (isTransitioning) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormErrorBoundary>
      <EnhancedFormDebugWrapper key={key}>
        <FormFactory />
      </EnhancedFormDebugWrapper>
    </FormErrorBoundary>
  );
};

export default FormFactoryLoader;
