import { createPartialSchema, FormValues } from '../../../types/form.types';
import { checkDate } from '../../../utils/pureFunctions';
import * as Yup from 'yup';

type PreparerCertificationSchemaType = Partial<FormValues> & {
  preparerContactAndCertification: {
    signature: string;
    dateOfSignature: string;
  };
};

const PreparerCertificationSchema = createPartialSchema<PreparerCertificationSchemaType>({
  preparerContactAndCertification: Yup.object({
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value) => !value || checkDate(value)),
  }),
});

export default PreparerCertificationSchema;
