/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import CertificationSignaturePage from '../../../pages/SharedPages/CertificationSignaturePage/CertificationSignaturePage';

const ApplicantCertificationPage: React.FC = () => {
  return (
    <CertificationSignaturePage
      question="Applicant's Certification and Signature"
      instructions="I certify, under penalty of perjury, that I provided or authorized all of the responses and information contained in and submitted with my application, I read and understand or, if interpreted to me in a language in which I am fluent by the interpreter listed in Section 11, understood, all of the responses and information contained in, and submitted with, my application (as explained to me by the interpreter), and that all of the responses and the information are complete, true, and correct. Furthermore, I authorized the release of any information from any and all of my records that USCIS may need to determine my eligibility for an immigration request and to other entities and persons where necessary for the administration and enforcement of U.S. immigration law."
      signatureLabel="Applicant's Signature"
      signaturePath="applicantContactAndCertification.signature"
      datePath="applicantContactAndCertification.dateOfSignature"
    />
  );
};

export default ApplicantCertificationPage;
