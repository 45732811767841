import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Header, Footer } from './containers';

const RootLayout: React.FC = (): JSX.Element => {
  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (!currentPath.includes('/form')) {
      // Clear form data when we're not in the form route
      queryClient.removeQueries({
        predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
      });
    }
  }, [location.pathname, queryClient]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default RootLayout;
