/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, FormHelperText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const EthnicityPage: React.FC = () => {
  return (
    <Field name="biographicInfo.ethnicity">
      {({ field, meta }: { field: any; meta: any }): ReactElement => (
        <FormControl
          component="fieldset"
          error={meta.touched && !!meta.error}
          sx={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            sx={{
              fontSize: '1rem',
              mb: 2,
              color: 'text.primary',
            }}
          >
            What is the ethnicity of the person who will receive the document?
          </FormLabel>
          <RadioGroup
            {...field}
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
          >
            <Box
              sx={{
                border: 1,
                borderColor: field.value === 'hispanicOrLatino' ? 'primary.main' : 'grey.300',
                borderRadius: 1,
                p: 2,
                flex: 1,
                bgcolor: field.value === 'hispanicOrLatino' ? 'primary.50' : 'background.paper',
              }}
            >
              <FormControlLabel
                value="hispanicOrLatino"
                control={
                  <Radio
                    icon={<CancelIcon sx={{ color: 'primary.main' }} />}
                    checkedIcon={<CancelIcon sx={{ color: 'primary.main' }} />}
                  />
                }
                label="Hispanic or Latino"
                sx={{
                  width: '100%',
                  margin: 0,
                  '.MuiFormControlLabel-label': {
                    flex: 1,
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                border: 1,
                borderColor: field.value === 'notHispanicOrLatino' ? 'primary.main' : 'grey.300',
                borderRadius: 1,
                p: 2,
                flex: 1,
                bgcolor: field.value === 'notHispanicOrLatino' ? 'primary.50' : 'background.paper',
              }}
            >
              <FormControlLabel
                value="notHispanicOrLatino"
                control={
                  <Radio
                    icon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                    checkedIcon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                  />
                }
                label="Not Hispanic or Latino"
                sx={{
                  width: '100%',
                  margin: 0,
                  '.MuiFormControlLabel-label': {
                    flex: 1,
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
          </RadioGroup>
          {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default EthnicityPage;
