/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import BooleanRadio from '../../SharedPages/BooleanRadio/BooleanRadio';

const HasInterpreterPage: React.FC = () => {
  return (
    <BooleanRadio
      question="Did you use a interpreter for this application?"
      fieldName="interpreterContactAndCertification.hasInterpreter"
    />
  );
};

export default HasInterpreterPage;
