import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '../../SharedPages/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const APPLIED_FOR_PASSPORT = {
  APPLIED_FOR_PASSPORT: {
    fieldKey: 'refugeeTravelDocumentInfo.appliedForPassport',
    question:
      'Since you were accorded refugee/asylee status, have you ever applied for and/or obtained a national passport, passport renewal, or entry permit of that country?',
  },
  APPLIED_FOR_PASSPORT_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.appliedForPassportExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const AppliedForPassport = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={APPLIED_FOR_PASSPORT.APPLIED_FOR_PASSPORT.question}
      fieldName={APPLIED_FOR_PASSPORT.APPLIED_FOR_PASSPORT.fieldKey}
    />
  );
};

export default AppliedForPassport;
