/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ReactElement } from 'react';
import { Field } from 'formik';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const LengthOfTripPage: React.FC = () => {
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Field name="proposedTravel.numberOfTrips">
          {({ field, meta }: { field: any; meta: any }): ReactElement => (
            <FormControl
              component="fieldset"
              error={meta.touched && !!meta.error}
              required
              sx={{ width: '100%' }}
            >
              <FormLabel
                component="legend"
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 400,
                  mb: 2,
                  color: 'text.primary',
                  '&.Mui-focused': {
                    color: 'text.primary',
                  },
                }}
              >
                How many trips do you intend to use this document?
              </FormLabel>

              <RadioGroup
                {...field}
                sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderColor: field.value === 'oneTrip' ? 'primary.main' : 'grey.300',
                    borderRadius: 1,
                    p: 2,
                    flex: 1,
                    bgcolor: field.value === 'oneTrip' ? 'primary.50' : 'background.paper',
                  }}
                >
                  <FormControlLabel
                    value="oneTrip"
                    control={
                      <Radio
                        icon={<CancelIcon sx={{ color: 'primary.main' }} />}
                        checkedIcon={<CancelIcon sx={{ color: 'primary.main' }} />}
                      />
                    }
                    label="One Trip"
                    sx={{
                      width: '100%',
                      margin: 0,
                      '.MuiFormControlLabel-label': {
                        flex: 1,
                        textAlign: 'center',
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    border: 1,
                    borderColor: field.value === 'moreThanOneTrip' ? 'primary.main' : 'grey.300',
                    borderRadius: 1,
                    p: 2,
                    flex: 1,
                    bgcolor: field.value === 'moreThanOneTrip' ? 'primary.50' : 'background.paper',
                  }}
                >
                  <FormControlLabel
                    value="moreThanOneTrip"
                    control={
                      <Radio
                        icon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                        checkedIcon={<CheckCircleIcon sx={{ color: 'success.main' }} />}
                      />
                    }
                    label="More than one trip"
                    sx={{
                      width: '100%',
                      margin: 0,
                      '.MuiFormControlLabel-label': {
                        flex: 1,
                        textAlign: 'center',
                      },
                    }}
                  />
                </Box>
              </RadioGroup>

              {meta.touched && meta.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1 }}
                >
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          )}
        </Field>
      </Box>

      <Box>
        <FormLabel
          sx={{
            fontSize: '1.25rem',
            fontWeight: 400,
            mb: 2,
            display: 'block',
            color: 'text.primary',
          }}
        >
          Expected Length of Trip
        </FormLabel>

        <Field name="proposedTravel.expectedLength">
          {({ field, meta }: { field: any; meta: any }): ReactElement => (
            <FormControl
              fullWidth
              error={meta.touched && !!meta.error}
            >
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  color: 'text.secondary',
                  fontSize: '0.875rem',
                }}
              >
                Days
              </Typography>
              <TextField
                {...field}
                type="number"
                inputProps={{
                  min: 1,
                  max: 999,
                }}
                value={field.value || ''}
                onChange={(e): void => {
                  const value = e.target.value;
                  if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 999)) {
                    field.onChange(e);
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#E0E0E0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#BDBDBD',
                    },
                  },
                }}
              />

              {meta.touched && meta.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1 }}
                >
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          )}
        </Field>
      </Box>
    </>
  );
};

export default LengthOfTripPage;
