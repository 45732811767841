import { createPartialSchema, FormValues } from '../../types/form.types';
import { booleanReqSchema } from '../../utils/I131.schema';

type EmploymentAuthorizationSchemaType = Partial<FormValues> & {
  eadForNewOrReparole: string;
};

const EmploymentAuthorizationSchema = createPartialSchema<EmploymentAuthorizationSchemaType>({
  eadForNewOrReparole: booleanReqSchema.required('Please select Yes or No'),
});

export default EmploymentAuthorizationSchema;
