import React, { ReactElement } from 'react';
import { useField } from 'formik';
import { TextField, Card, CardContent, Alert, FormHelperText, Box, Typography } from '@mui/material';

type ExplanationBoxProps = {
  question?: string;
  label?: string;
  name: string;
  helperText?: string;
  maxCharacters?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  minRows?: number;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
};

const ExplanationBox: React.FC<ExplanationBoxProps> = ({
  question,
  label = 'Provide an explanation.',
  name,
  maxCharacters = 1000,
  helperText,
  placeholder = 'Enter your explanation here...',
  required = false,
  disabled = false,
  multiline = true,
  minRows = 3,
  fullWidth = true,
  variant = 'outlined',
}): ReactElement => {
  const [charsRemaining, setCharsRemaining] = React.useState(maxCharacters);
  const [field, meta] = useField(name);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const inputLength = e.target.value.length;
    setCharsRemaining(maxCharacters - inputLength);
    field.onChange(e);
  };

  // Determine if the field is in an error state
  const isError = meta.touched && Boolean(meta.error);

  return (
    <Card
      variant="outlined"
      sx={{
        width: fullWidth ? '100%' : 'auto',
        '& .MuiCardContent-root:last-child': {
          paddingBottom: 2,
        },
      }}
    >
      <CardContent>
        {question && (
          <Alert
            severity="info"
            sx={{
              mb: 2,
              '& .MuiAlert-message': {
                width: '100%',
              },
            }}
          >
            {question}
          </Alert>
        )}

        <TextField
          {...field}
          label={label}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          multiline={multiline}
          minRows={minRows}
          maxRows={10}
          variant={variant}
          fullWidth={fullWidth}
          inputProps={{
            maxLength: maxCharacters,
            'aria-invalid': isError,
          }}
          onChange={handleInputChange}
          error={isError}
          helperText={isError ? meta.error : undefined}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: variant === 'filled' ? 'action.hover' : 'background.paper',
            },
            '& .Mui-disabled': {
              bgcolor: 'action.disabledBackground',
            },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
          }}
        >
          {helperText && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
          <Typography
            variant="caption"
            color={charsRemaining < 50 ? 'error' : 'text.secondary'}
            sx={{
              transition: 'color 0.2s',
              ml: 'auto',
            }}
          >
            {charsRemaining} characters remaining
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExplanationBox;
