// utils/formStateUtils.ts
import { QueryClient } from '@tanstack/react-query';

export const clearFormState = (queryClient: QueryClient, formId?: string): void => {
  // Clear React Query cache
  queryClient.removeQueries({
    predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
  });

  // Clear specific form data
  if (formId) {
    queryClient.setQueryData(['form-data', formId], null);
    localStorage.removeItem(`form_${formId}`);
  }

  // Clear any other form-related localStorage items
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('form_')) {
      localStorage.removeItem(key);
    }
  });

  // Reset any form-specific state in sessionStorage if you're using it
  sessionStorage.removeItem('lastFormState');
};
