import * as React from 'react';
import HelperNamePage from '../../../pages/SharedPages/HelperNamePage/HelperNamePage';

const InterpreterNamePage: React.FC = () => {
  return (
    <HelperNamePage
      question="Interpreter's Full Name"
      familyNamePath={'interpreterContactAndCertification.fullName.familyName'}
      givenNamePath={'interpreterContactAndCertification.fullName.givenName'}
      businessPath={'interpreterContactAndCertification.businessName'}
      familyNameLabel="Interpreter's Family Name (Last Name)"
      givenNameLabel="Interpreter's Given Name (First Name)"
      businessLabel="Interpreter's Business or Organization Name (if any)"
    />
  );
};

export default InterpreterNamePage;
